import { StorageLike } from 'pinia-plugin-persistedstate';

/**
 * Checks whether current window context is an iframe.
 * @returns
 */
export function isIFrameContext(): boolean {
  return window.self !== window.top;
}

/**
 * Read only storage to share data between
 * parent and child frame without child
 * overwriting parent data.
 */
const iframePiniaStorage: StorageLike = {
  getItem(key) {
    return localStorage.getItem(key);
  },
  setItem(key, value) {
    if (isIFrameContext()) return;
    return localStorage.setItem(key, value);
  },
};

export function getReadonlyPiniaChildStorage() {
  return iframePiniaStorage;
}
