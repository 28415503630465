import { isEqual } from 'lodash';
import { defineStore } from 'pinia';

import { useGlobalStore } from '@/common/globalStore';
import {
  getFlatSortedSections,
  getSectionDescendants,
  useWbsSectionStore,
  WbsSection,
} from '@/features/projectStructure';
import { useRTCController } from '@/features/realTimeCollaboration';
import { omitKeys } from '@/helpers/utils/objects';
import { getReadonlyPiniaChildStorage } from '@/utils/iframe';

export type SchedulePermanentFilterState = {
  sections: string[];
};

export type ScheduleTemporaryFilterState = {
  sections: string[];
  contributorGroups: string[];
  trades: string[];
  range: {
    start: string;
    end: string;
  } | null;
};

type InternalSchedulePermanentFilterState = SchedulePermanentFilterState & {
  allSections: string[];
};

type InternalScheduleTemporaryFilterState = ScheduleTemporaryFilterState & {
  allSections: string[];
};

const getInitialTemporaryFilter = (): InternalScheduleTemporaryFilterState => ({
  sections: [],
  allSections: [],
  contributorGroups: [],
  trades: [],
  range: null,
});

const getInitialPermanentFilter = (): InternalSchedulePermanentFilterState => ({
  sections: [],
  allSections: [],
});

export type ScheduleFilterState = {
  temporaryFilter: InternalScheduleTemporaryFilterState;
  permanentFilter: InternalSchedulePermanentFilterState;
};

export type ScheduleFilterStore = ReturnType<typeof useScheduleFilterStore>;

export const useScheduleFilterStore = defineStore(
  'schedule-filter-store-1',
  () => {
    const globalStore = useGlobalStore();
    const sectionStore = useWbsSectionStore();
    const projectFilters = ref<Record<string, ScheduleFilterState>>({});

    const currentFilter = computed(() => {
      return (
        projectFilters.value[globalStore.scheduleProjectId ?? ''] ?? {
          temporaryFilter: getInitialTemporaryFilter(),
          permanentFilter: getInitialPermanentFilter(),
        }
      );
    });

    const syncSectionFilterStateWithCurrentSections = (
      filterState: { sections: string[]; allSections: string[] },
      currentSections: WbsSection[],
      key: 'temporaryFilter' | 'permanentFilter',
    ) => {
      if (!filterState.sections.length || !currentSections.length) return;

      // automatically add potentially new sections if they full-fill conditions
      const newFilteredSections = [...filterState.sections];
      getFlatSortedSections(currentSections).forEach((section) => {
        if (filterState.allSections.includes(section.id)) return;
        if (
          !section.parentId ||
          getSectionDescendants(currentSections, section.parentId).some((descendant) =>
            newFilteredSections.includes(descendant.id),
          )
        ) {
          newFilteredSections.push(section.id);
        }
      });

      // automatically remove sections that have been deleted
      filterState.allSections.forEach((sectionId) => {
        const matchingSectionInCurrentSections = currentSections.find(
          (section) => section.id === sectionId,
        );
        if (!matchingSectionInCurrentSections) {
          newFilteredSections.splice(newFilteredSections.indexOf(sectionId), 1);
        }
      });

      if (!isEqual(newFilteredSections, filterState.sections)) {
        projectFilters.value[globalStore.scheduleProjectId ?? ''] = {
          ...projectFilters.value[globalStore.scheduleProjectId ?? ''],
          [key]: {
            ...filterState,
            sections: newFilteredSections,
            allSections: currentSections.map((section) => section.id),
          },
        };
      }
    };

    const updateSectionFilterStateWithLatestSections = () => {
      const filter = projectFilters.value[globalStore.scheduleProjectId ?? ''] ?? {
        temporaryFilter: getInitialTemporaryFilter(),
        permanentFilter: getInitialPermanentFilter(),
      };
      // when switching to a project/alternative we reload the existing filters and auto-update them (e.g. with newly created sections).
      // This check is for safety reasons, because this method can be triggered, before the sections in the store are done resetting,
      // so we would update the filter with sections from a different project
      const currentSections =
        globalStore.scheduleProjectId === useRTCController()?.getContext()?.projectId
          ? sectionStore.wbsSectionsList
          : [];

      syncSectionFilterStateWithCurrentSections(
        filter.temporaryFilter,
        currentSections,
        'temporaryFilter',
      );
      syncSectionFilterStateWithCurrentSections(
        filter.permanentFilter,
        currentSections,
        'permanentFilter',
      );
    };

    watch([() => globalStore.scheduleProjectId, () => sectionStore.wbsSectionsList], () => {
      updateSectionFilterStateWithLatestSections();
    });

    const isTemporaryFilterActive = computed(() => {
      return Boolean(
        currentFilter.value.temporaryFilter.range ||
          currentFilter.value.temporaryFilter.sections.length ||
          currentFilter.value.temporaryFilter.trades.length ||
          currentFilter.value.temporaryFilter.contributorGroups.length,
      );
    });

    const isPermanentFilterActive = computed(() => {
      return Boolean(currentFilter.value.permanentFilter.sections.length);
    });

    const appliedTemporaryFilters = computed(() => {
      const filters: { filterKey: keyof ScheduleTemporaryFilterState; i18nKey: string }[] = [];
      if (
        currentFilter.value.temporaryFilter.range?.start &&
        currentFilter.value.temporaryFilter.range?.end
      ) {
        filters.push({ filterKey: 'range', i18nKey: 'Calendar.filterBanner.range' });
      }
      if (currentFilter.value.temporaryFilter.sections.length) {
        filters.push({
          filterKey: 'sections',
          i18nKey: 'Calendar.filterBanner.sections',
        });
      }
      if (currentFilter.value.temporaryFilter.trades.length) {
        filters.push({ filterKey: 'trades', i18nKey: 'Calendar.filterBanner.trades' });
      }
      if (currentFilter.value.temporaryFilter.contributorGroups.length) {
        filters.push({
          filterKey: 'contributorGroups',
          i18nKey: 'Calendar.filterBanner.company',
        });
      }
      return filters;
    });

    const appliedPermanentFilters = computed(() => {
      const filters: { filterKey: keyof SchedulePermanentFilterState; i18nKey: string }[] = [];
      if (currentFilter.value.permanentFilter.sections.length) {
        filters.push({
          filterKey: 'sections',
          i18nKey: 'Calendar.filterBanner.sections',
        });
      }
      return filters;
    });

    const applyTemporaryFilter = (state: ScheduleTemporaryFilterState) => {
      if (
        currentFilter.value.temporaryFilter &&
        isEqual(state, omitKeys(currentFilter.value.temporaryFilter, ['allSections']))
      ) {
        return;
      }
      projectFilters.value[globalStore.scheduleProjectId ?? ''] = {
        ...currentFilter.value,
        temporaryFilter: {
          ...state,
          allSections: sectionStore.wbsSectionsList.map((section) => section.id),
        },
      };
    };

    const resetTemporaryFilter = (key?: keyof ScheduleTemporaryFilterState) => {
      applyTemporaryFilter(
        key
          ? {
              ...currentFilter.value.temporaryFilter,
              [key]: getInitialTemporaryFilter()[key],
            }
          : getInitialTemporaryFilter(),
      );
    };

    const applyPermanentFilter = (state: SchedulePermanentFilterState) => {
      if (
        currentFilter.value.permanentFilter &&
        isEqual(state, omitKeys(currentFilter.value.permanentFilter, ['allSections']))
      ) {
        return;
      }
      projectFilters.value[globalStore.scheduleProjectId ?? ''] = {
        ...currentFilter.value,
        permanentFilter: {
          ...state,
          allSections: sectionStore.wbsSectionsList.map((section) => section.id),
        },
      };
      // NOTE: whenever we set a permanent filter, we also reset the structure of the temporary filter, as otherwise strange things might happen
      resetTemporaryFilter('sections');
    };

    const resetPermanentFilter = (key?: keyof SchedulePermanentFilterState) => {
      applyPermanentFilter(
        key
          ? {
              ...currentFilter.value.permanentFilter,
              [key]: getInitialPermanentFilter()[key],
            }
          : getInitialPermanentFilter(),
      );
    };

    return {
      projectFilters,
      currentFilter,
      isTemporaryFilterActive,
      isPermanentFilterActive,
      appliedTemporaryFilters,
      appliedPermanentFilters,
      applyTemporaryFilter,
      resetTemporaryFilter,
      applyPermanentFilter,
      resetPermanentFilter,
      updateSectionFilterStateWithLatestSections,
    };
  },
  { persist: { pick: ['projectFilters'], storage: getReadonlyPiniaChildStorage() } },
);
