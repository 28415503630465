<template>
  <div class="project-planning-mode-banner">
    <h4 class="project-planning-mode-banner-title">
      {{ $t('objects.projectAlternatives.title') }}
    </h4>
    <button class="tw-inline-flex" type="button" @click="exitPlanningMode">
      <DsSvgIcon icon="x-close" size="sm" />
    </button>
  </div>
</template>
<script setup lang="ts">
import { useProjectAlternativeStore } from '../store/projectAlternativeStore';

const alternativeStore = useProjectAlternativeStore();

const exitPlanningMode = () => {
  alternativeStore.exitPlanningMode();
};
</script>
<style scoped>
.project-planning-mode-banner {
  @apply tw-w-full tw-bg-attention-500 tw-text-white;
  @apply tw-flex tw-justify-between tw-items-center tw-p-4;
}
.project-planning-mode-banner-title {
  @apply tw-ds-h4;
}
</style>
