import { UseQueryOptions } from '@vue/apollo-composable';

import {
  RegisterUserMutationVariables,
  UpdateUserMutationVariables,
  UserFragment,
  UserProfilePictureFragment,
  UserSimpleFragment,
} from '@/graphql/__generated__/graphql';
import { CRUDRepository, Entity, QueryResult } from '@/interfaces/repositories/base';

export interface FindAllUsersVariables {
  tenantId: string;
}

export interface UserPreview extends UserSimpleFragment {}

export interface UserProfilePicture extends UserProfilePictureFragment {}

export type CreateUserVariables = RegisterUserMutationVariables['input'];

export interface UpdateUserVariables extends UpdateUserMutationVariables {
  previewUrl?: string | null;
}

export interface UserMutateReturn extends Entity {}

export enum UserAccountStatus {
  Unknown = 'UNKNOWN',
  Invited = 'INVITED',
  Active = 'ACTIVE',
}

export type SignUpConfiguration = {
  domain: string | undefined;
  enforceMSSO: boolean;
};

export type UserInvitation = {
  configuration: SignUpConfiguration;
};

export interface UserRepository
  extends Omit<
    CRUDRepository<
      CreateUserVariables,
      UpdateUserVariables,
      UserFragment,
      UserPreview,
      FindAllUsersVariables,
      UserMutateReturn
    >,
    'useDelete'
  > {
  /** Fetch own user */
  fetchOwn(options?: UseQueryOptions): QueryResult<UserFragment | undefined | null>;

  getOwnAccountStatus(): Promise<UserAccountStatus>;

  fetchInvitation(invitationToken: string): Promise<UserInvitation | undefined>;
}
