<template>
  <div class="project-template-mode-banner">
    <h4 class="tw-ds-h4">
      {{ $t(titleKey) }}
    </h4>
    <p v-if="subtitleKey" class="tw-ds-text-xs">{{ $t(subtitleKey) }}</p>
  </div>
</template>

<script setup lang="ts">
import { useProjectTemplatesStore } from '@/features/projectTemplates';

const projectTemplatesStore = useProjectTemplatesStore();

const titleKey = computed(() => {
  return projectTemplatesStore.mode === 'edit'
    ? 'projectTemplates.bannerEditModeTitle'
    : 'projectTemplates.bannerPreviewModeTitle';
});
const subtitleKey = computed(() => {
  return projectTemplatesStore.mode === 'edit' ? 'projectTemplates.bannerEditModeSubtitle' : null;
});
</script>

<style scoped>
.project-template-mode-banner {
  @apply tw-w-full tw-bg-petrol-400 tw-text-white;
  @apply tw-p-4;
}
</style>
