import { debounce } from '@/helpers/utils/functions';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { ScheduleScrollHelper } from '@/services/store/schedule/types';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { SchedulerPopupComponent } from '@/services/store/schedulePopup';

import { getAdjustedPopupMouseEvent } from './events';

export interface ScrollListeners {
  horizontalscroll: () => void;
  scroll: () => void;
}

export function getScrollListeners(
  store: ScheduleStore,
  scrollHelper: ScheduleScrollHelper,
): ScrollListeners {
  const persistScroll = getScrollPersistence(store, scrollHelper);

  return {
    horizontalscroll: () => {
      startScroll(store, scrollHelper);
      persistScroll();
    },
    scroll: () => {
      startScroll(store, scrollHelper);
      persistScroll();
    },
  };
}

function startScroll(store: ScheduleStore, scrollHelper: ScheduleScrollHelper): void {
  if (!scrollHelper.isScrolling) {
    scrollHelper.isScrolling = true;
    store.closePopup(SchedulerPopupComponent.EVENT_HOVER);
  }
}

function getScrollPersistence(
  store: ScheduleStore,
  scrollHelper: ScheduleScrollHelper,
): () => void {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  return debounce(() => {
    const scheduler = getScheduler();
    if (store.initialized) {
      const date = scheduler?.viewportCenterDate?.getTime() ?? null;
      const row = scheduler?.firstVisibleRow ?? null;

      if (date) {
        scheduleAppearanceStore.setScrollDate(date);
      }
      if (row) {
        scheduleAppearanceStore.setScrollRowIdx(row.dataIndex);
      }
    }

    getOpenEventHoverPopupAfterScrollIfNecessary(store);
    scrollHelper.isScrolling = false;
  }, 500);
}

function getOpenEventHoverPopupAfterScrollIfNecessary(store: ScheduleStore): void {
  const element = document.querySelector('.b-sch-event-hover') as HTMLElement | undefined;
  if (!element) return;
  const eventId = element.getAttribute('data-event-id');
  if (!eventId) return;
  const event = store.entities.events.get(eventId);
  if (!event) return;

  store.openPopup({
    component: SchedulerPopupComponent.EVENT_HOVER,
    payload: {
      entity: event.entity,
      id: event.id,
      mouseEvent: getAdjustedPopupMouseEvent(new MouseEvent('custom'), element),
    },
    submissive: true,
  });
}
