<template>
  <Transition name="horizontal-slide" mode="out-in">
    <slot />
  </Transition>
</template>

<style>
.horizontal-slide-enter-active,
.horizontal-slide-leave-active {
  transition: all 0.2s ease;
}

.horizontal-slide-enter-from,
.horizontal-slide-leave-to {
  opacity: 0;
  @apply tw-translate-x-full;
}
</style>
