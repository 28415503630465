import { DomConfig, TreeColumnConfig } from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { WbsSectionEntity } from '@/common/types';
import { useCollisionStore } from '@/features/collisions';
import { useCollisionsInRange } from '@/features/collisions/composables/useCollisionsInRange';
import { useProjectStore } from '@/features/projects/projectStore';
import {
  getSectionAnalyticsType,
  getSectionDescendants,
  getSectionSiblings,
  renderSectionRow,
  useWbsSectionStore,
  WbsSection,
} from '@/features/projectStructure';
import {
  SectionSummaryHoveredEvent,
  SectionVisibilityToggledEvent,
} from '@/features/projectStructure/analytics';
import { useScheduleSectionActions } from '@/features/projectStructure/utils/sectionOperations';
import { refreshScheduleUI } from '@/features/schedule/bryntum/schedulerInteractions';
import { useOpenSection } from '@/features/sections/util';
import { buildAddress } from '@/helpers/address';
import { isEqual } from '@/helpers/utils/objects';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { useLoggingService } from '@/services/logging/composable';
import { ScheduleStore, useScheduleStore } from '@/services/store/schedule';
import { getEmptyResourceId, getMainResourceId } from '@/services/store/schedule/parsers/base';
import { ColumnRendererData } from '@/services/store/schedule/types';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { SchedulerPopupComponent, useSchedulePopupStore } from '@/services/store/schedulePopup';

import { getScheduler } from '../../integrations/scheduler';
import ScheduleHeaderCornerCell, {
  ScheduleHeaderCornerCellId,
  ScheduleHeaderCornerCellProps,
} from './renderers/ScheduleHeaderCornerCell';

const POPUP_VERTICAL_OFFSET = 10;

export function getColumns(
  store: ScheduleStore,
  i18n: Composer,
  loggingService: LoggingService,
): Partial<TreeColumnConfig>[] {
  const wbsSectionStore = useWbsSectionStore();
  const scheduleAppearanceStore = useScheduleAppearanceStore();

  const collisionStore = useCollisionStore();
  const { collisionsBeforeRange, collisionsAfterRange } = useCollisionsInRange();

  // We need to watch the collisions and update the collision row, since Bryntum cannot watch external data
  watch([collisionsBeforeRange, collisionsAfterRange], (next, prev) => {
    if (isEqual(next, prev)) return;
    refreshScheduleUI(getScheduler());
  });

  const { getIsMouseDown, getOpenToolbarSectionId, setOpenToolbarSectionId } = useOpenSection();

  return [
    {
      draggable: false,
      field: 'name',
      fitMode: 'value',
      indentSize: 0.5,
      minWidth: 210,
      sortable: false,
      text: '',
      type: 'tree',
      width: scheduleAppearanceStore.splitterWidth,
      // @ts-expect-error NOTE: Bryntum returns static types, while they allow to define dynamic types, so all their typing is basically useless
      renderer: (data: ColumnRendererData) => {
        renderScheduleHeaderCornerCell({
          i18n,
          collisionStore,
          collisionsBeforeRange: collisionsBeforeRange.value,
          collisionsAfterRange: collisionsAfterRange.value,
          loggingService,
        });

        const featureAccessStore = useFeatureAccessStore();
        const { onAdd, onCopy, onDelete, onUpdate, onIndent, onOutdent } =
          useScheduleSectionActions(i18n);

        const { record } = data;

        if (record.id === getMainResourceId()) {
          return renderMainResourceRow(store, i18n);
        }
        if (record.id === getEmptyResourceId()) {
          return '';
        }

        return renderSectionRow(data, {
          getShowEditActions: () =>
            featureAccessStore.features.SCHEDULE_PLANNING().value.write &&
            !store.utils.disableSectionInteractions,
          shouldShowSectionSummary: true,
          getWbsSectionsTree: () => wbsSectionStore.getOrderedWbsSectionsTree(),
          getWbsSectionById: (id: string): WbsSection | undefined =>
            wbsSectionStore.wbsSections.get(id),
          getCollapsedRowIds: () => [...scheduleAppearanceStore.collapsedRowIds.values()],
          getSectionSummaryEventById: (id: string) => store.entities.sectionSummaryEvents.get(id),
          i18n,
          collapseToggled: onCollapseToggled,
          showSectionSummary,
          hideSectionSummary,
          addSection: onAdd,
          copySection: onCopy,
          deleteSection: onDelete,
          updateSection: onUpdate,
          indentSection: onIndent,
          outdentSection: onOutdent,
          getOpenToolbarSectionId,
          setOpenToolbarSectionId,
          getIsMouseDown,
          isReadOnly: !featureAccessStore.features.SCHEDULE_PLANNING().value.write,
          hideActions: !featureAccessStore.uiState.showSectionActions,
        });
      },
    },
  ];
}

const showSectionSummary = (wbsSection: WbsSection, event: MouseEvent) => {
  const { left, bottom } = (event.target as HTMLElement).getBoundingClientRect();
  useSchedulePopupStore().openPopup({
    component: SchedulerPopupComponent.EVENT_HOVER,
    payload: {
      entity: NodeName.SECTION,
      id: wbsSection.id,
      mouseEvent: new MouseEvent(event.type, {
        ...event,
        clientX: left,
        clientY: bottom + POPUP_VERTICAL_OFFSET,
      }),
    },
  });

  useLoggingService().trackEvent(
    new SectionSummaryHoveredEvent({
      id: wbsSection.id,
      type: getSectionAnalyticsType(useWbsSectionStore().wbsSectionsList, wbsSection),
    }),
  );
};

const hideSectionSummary = () => {
  useSchedulePopupStore().closePopup(SchedulerPopupComponent.EVENT_HOVER);
};

const onCollapseToggled = (
  sectionId: string,
  shouldSetToCollapsed: boolean,
  isAltModifier: boolean,
  isShiftAltModifier: boolean,
) => {
  const sectionStore = useWbsSectionStore();
  const sections = sectionStore.wbsSectionsList;
  const wbsSection = sectionStore.wbsSections.get(sectionId);
  if (!wbsSection) return;

  let sectionsToToggle: WbsSectionEntity[] = [wbsSection];
  let rowsAffected: 'one' | 'siblings' | 'children' = 'one';

  if (isShiftAltModifier) {
    sectionsToToggle = getSectionDescendants(sections, wbsSection, !shouldSetToCollapsed);

    shouldSetToCollapsed = shouldSetToCollapsed
      ? checkShouldCollapseDescendantSections(sectionsToToggle)
      : false;

    rowsAffected = 'children';
  } else if (isAltModifier) {
    sectionsToToggle = getSectionSiblings(sections, wbsSection);
    rowsAffected = 'siblings';
  }

  setCollapsedStateForResources(
    sectionsToToggle.map((s) => s.id),
    shouldSetToCollapsed,
  );

  useLoggingService().trackEvent(
    new SectionVisibilityToggledEvent({
      isCollapsed: shouldSetToCollapsed,
      rowsAffected,
      type: getSectionAnalyticsType(sectionsToToggle, wbsSection),
    }),
  );
};

const checkShouldCollapseDescendantSections = (sections: WbsSectionEntity[]) => {
  const collapsedRowIds = useScheduleAppearanceStore().collapsedRowIds;
  return sections.every(({ id }) => !collapsedRowIds.has(id));
};

const setCollapsedStateForResources = (sectionIds: string[], isCollapsed: boolean) => {
  useScheduleStore().setCollapsedStateForResources(sectionIds, isCollapsed);
};

function renderMainResourceRow(store: ScheduleStore, i18n: Composer): DomConfig {
  const projectStore = useProjectStore();
  const wrapper = document.createElement('div');
  wrapper.id = 'main-resource-row-label';
  wrapper.className = 'tw-flex tw-items-center tw-justify-center';
  wrapper.textContent = `${i18n.t('objects.leanProject.schedule.mainSlot')}`;

  const hasNoProjectAddress =
    !!projectStore.currentProject && isEqual(projectStore.currentProject.address, buildAddress());

  if (hasNoProjectAddress) {
    const iconWrapper = document.createElement('div');
    iconWrapper.className =
      'tw-flex tw-items-center tw-justify-center tw-ml-2 tw-h-8 tw-rounded-full tw-w-8 hover:tw-bg-grey-50 hover:tw-cursor-pointer';
    iconWrapper.innerHTML = '<i class="material-icons tw-text-yellow-600">warning</i>';
    wrapper.appendChild(iconWrapper);

    const tooltip = renderCustomSchedulerTooltip(
      `${i18n.t('objects.leanProject.planning.holidayBanner')}`,
      `${getMainResourceId()}-address`,
    );

    iconWrapper.addEventListener('mouseenter', () => {
      openCustomScheduleTooltipAt(tooltip, iconWrapper);
      document.body.appendChild(tooltip);
    });
    iconWrapper.addEventListener('mouseleave', () => {
      document.body.removeChild(tooltip);
    });
    iconWrapper.addEventListener('click', () => {
      store.openPopup({
        component: SchedulerPopupComponent.PROJECT_ADDRESS_UPDATE,
        payload: {},
      });
    });
  }

  return {
    children: [wrapper],
  };
}

function renderScheduleHeaderCornerCell(props: ScheduleHeaderCornerCellProps): void {
  const cornerCell = document.getElementById(ScheduleHeaderCornerCellId);
  if (cornerCell) {
    cornerCell.remove();
  }

  document
    .querySelector("header > div[id$='lockedSubgrid-header']")
    ?.appendChild(ScheduleHeaderCornerCell(props));
}

function renderCustomSchedulerTooltip(innerHtml: string, id: string) {
  const maybeTooltip = document.getElementById(id);
  if (maybeTooltip) maybeTooltip.remove();
  const tooltip = document.createElement('div');
  tooltip.id = id;
  const tooltipClasses = ['ds-tooltip'];
  tooltip.className = tooltipClasses.join(' ');
  tooltip.innerHTML = innerHtml;

  return tooltip;
}

function openCustomScheduleTooltipAt(tooltip: HTMLElement, wrapper: Element): void {
  const wrapperRect = wrapper.getBoundingClientRect();
  Object.assign(tooltip.style, {
    top: `${wrapperRect.top + wrapperRect.height + 8}px`,
    left: `${wrapperRect.left}px`,
  });
}
