import {
  ApolloQueryResult,
  DocumentNode,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  NetworkStatus,
} from '@apollo/client/core';
import unionBy from 'lodash/unionBy';
import { computed, Ref, ref, watch } from 'vue';

import { makeChunks, zip } from '@/helpers/utils/arrays';
import { isEqual } from '@/helpers/utils/objects';
import { Entity, PaginationResult } from '@/interfaces/repositories/base';
import { useApolloClient } from '@/plugins/apollo';

/** Uses cursor to paginate results, and fetches only a specified number of results per page
 * @param result formatted result of the related query
 * @param fetchMoreFromQuery fetch more function from `useQuery` to update result
 * @param networkStatus status of the network request
 * @param limit number of elements per page
 * @param replace indicator, if pages should be concatenated or replaced
 */
export function useCursorBasedPagination<
  TRawResult extends Dictionary,
  TResult extends Dictionary,
  TVariables extends { after?: string | null },
>(
  { result: rawResult, key }: { result: Ref<TRawResult | undefined>; key: keyof TRawResult },
  result: Ref<TResult[] | readonly TResult[]>,
  fetchMoreFromQuery: <K extends keyof TVariables>(
    options: FetchMoreQueryOptions<TVariables, K> & FetchMoreOptions<TRawResult, TVariables>,
  ) => Promise<ApolloQueryResult<TRawResult>> | undefined,
  networkStatus: Ref<number | undefined>,
  limit?: Ref<number | undefined>,
  replace = true,
): PaginationResult & { result: Ref<TResult[]> } {
  const page = ref(0);
  const maxLoadedPage = ref(0);
  const fetchNextLoading = ref(false);
  const limits = ref(limit?.value ? [limit.value] : undefined);

  // rebase page, if the query was executed again
  watch(networkStatus, (status, prevStatus) => {
    if (
      // -99 is not a valid status and if the networkStatus is undefined nothing should happen
      [NetworkStatus.refetch, NetworkStatus.loading].includes(prevStatus ?? -99) &&
      status === NetworkStatus.ready
    ) {
      page.value = 0;
      maxLoadedPage.value = 0;
    }
  });

  const hasNextPage = computed(
    () =>
      page.value < maxLoadedPage.value ||
      (rawResult.value ? rawResult.value[key].pageInfo?.hasNextPage : false),
  );
  const hasPreviousPage = computed(() => page.value > 0);

  const paginatedResult = computed((): TResult[] => {
    if (!limits?.value || !replace) return result.value as TResult[];
    const chunks = makeChunks(result.value as TResult[], limits.value);
    if (!chunks.length) return [];
    // return chunk for local page position to replace the previous page result
    return chunks[Math.min(chunks.length - 1, page.value)];
  });

  function fetchNext(newLimit?: number): Promise<void> {
    if (!rawResult.value) return Promise.reject();

    const { pageInfo } = rawResult.value[key];
    if (limits.value && newLimit) limits.value.push(newLimit);
    if (page.value + 1 > maxLoadedPage.value && pageInfo?.hasNextPage) {
      fetchNextLoading.value = true;
      maxLoadedPage.value = page.value + 1;
      const promise = fetchMoreFromQuery({
        variables: {
          after: pageInfo.endCursor,
          ...(newLimit ? { limit: newLimit } : {}),
        } as Partial<TVariables>,
      })?.then(() => {
        fetchNextLoading.value = false;
      });
      if (!promise) return Promise.reject();

      page.value++;
      return promise;
    }

    page.value++;
    return Promise.resolve();
  }

  function fetchPrevious(): void {
    if (page.value > 0) page.value--;
  }

  return {
    result: paginatedResult,
    hasNextPage,
    hasPreviousPage,
    fetchNextLoading,
    fetchNext,
    fetchPrevious,
  };
}

interface PaginatableQueryVariables {
  limit: number;
  after?: string;
}

interface PaginatableQueryResult {
  totalCount: number;
  pageInfo: {
    endCursor?: string | null;
    hasNextPage: boolean;
  };
}

export type QueryDataMerge<TQueryData> = (args: {
  existing: TQueryData[];
  newData: TQueryData[];
}) => TQueryData[];

interface PaginationReturn<QueryData> {
  fetchNext: (newLimit?: number) => Promise<void>;
  reset: () => void;
  refetch: (refetchLimit: number, compareResults: boolean) => Promise<QueryData[]>;
  fetchNew: () => Promise<void>;
  dispose: () => void;
}

export type UseCursorBasedPaginationWithoutCacheOptions<TQueryData, TQueryVariables> = {
  mergeData?: QueryDataMerge<TQueryData>;
  fetchNew?: {
    varBuilder: (variables: TQueryVariables) => TQueryVariables;
    merge: QueryDataMerge<TQueryData>;
  };
};

export function useCursorBasedPaginationWithoutCache<
  QueryType extends Dictionary,
  QueryVariables extends PaginatableQueryVariables,
  QueryResult extends PaginatableQueryResult,
  QueryData extends Entity,
>(
  query: DocumentNode,
  queryVariables: QueryVariables,
  key: keyof QueryType,
  data: {
    data: Ref<QueryData[]>;
    loading: Ref<boolean>;
    error: Ref<Error | undefined>;
    hasNextPage: Ref<boolean>;
  },
  parseEntities: (data: QueryResult) => QueryData[],
  options?: UseCursorBasedPaginationWithoutCacheOptions<QueryData, QueryVariables>,
): PaginationReturn<QueryData> {
  const client = useApolloClient();
  let endCursor = undefined as string | undefined;
  let disposed = false;

  const mergeData = options?.mergeData;

  const backendQuery = (newCursor?: string, newLimit?: number, useBeforeCursor = false) => {
    return client.query<QueryType, QueryVariables>({
      query,
      variables: {
        ...queryVariables,
        limit: newLimit || queryVariables.limit,
        after: !useBeforeCursor ? newCursor || queryVariables.after : undefined,
      },
      fetchPolicy: 'network-only',
    });
  };

  const executeQueryAndParseResult = async (newLimit?: number, setLoading = true) => {
    if (disposed) return;
    data.loading.value = !!setLoading;
    const {
      data: queryData,
      loading: queryLoading,
      error: queryError,
    } = await backendQuery(endCursor, newLimit);

    const extractedQueryData = (queryData as QueryType)[key] as QueryResult;

    const mergeResponses = () => {
      const parsedData = parseEntities(extractedQueryData);

      if (!data.data.value.length) return parsedData;

      if (mergeData) return mergeData({ existing: data.data.value, newData: parsedData });
      return unionBy(parsedData, data.data.value, 'id');
    };

    if (disposed) return;

    // Add to existing results or set result array
    // TODO: Check if the concatination is in correct order
    data.data.value = mergeResponses();
    data.hasNextPage.value =
      extractedQueryData.pageInfo.hasNextPage ||
      data.data.value.length < extractedQueryData.totalCount;
    endCursor = extractedQueryData.pageInfo.endCursor || undefined;
    data.loading.value = queryLoading;
    data.error.value = queryError;
    data.loading.value = false;
  };

  executeQueryAndParseResult();

  const fetchNext = async (newLimit?: number) => {
    if (!data.hasNextPage.value || data.loading.value) {
      return;
    }
    await executeQueryAndParseResult(newLimit, false);
  };

  /**
   * Refetches data from backend, uses same query as in the init process
   * @param refetchLimit limit used as maximum number of refetched results
   * @param compareResults compares the refetched results to the current ones, might reduce perfomance for this function
   * @returns fetched data
   */
  const refetch = async (refetchLimit: number, compareResults: boolean) => {
    const { data: queryData, error: queryError } = await backendQuery(
      endCursor,
      refetchLimit,
      true,
    );
    const extractedQueryData = (queryData as QueryType)[key] as QueryResult;
    const refetchedEntities = parseEntities(extractedQueryData);
    if (compareResults) {
      const numberOfRefetchedEntities = refetchedEntities.length;
      const isRefetchNumberSmallerOrEqual = numberOfRefetchedEntities <= data.data.value.length;
      const batchOfExistingData = data.data.value.slice(0, numberOfRefetchedEntities);

      const areResultsEqual =
        isRefetchNumberSmallerOrEqual &&
        zip<[QueryData, QueryData]>(refetchedEntities, batchOfExistingData).every(
          ([refetchedEntry, existingEntry]) => isEqual(refetchedEntry, existingEntry),
        );

      if (areResultsEqual) {
        return data.data.value;
      }
    }

    if (!disposed) {
      data.data.value = refetchedEntities;
      data.hasNextPage.value = extractedQueryData.pageInfo.hasNextPage;
      endCursor = extractedQueryData.pageInfo.endCursor || undefined;
      data.error.value = queryError;
    }
    return refetchedEntities;
  };

  const fetchNew = async () => {
    if (!options?.fetchNew) return;

    const { merge, varBuilder } = options.fetchNew;

    if (disposed) return;

    const result = await client.query<QueryType, QueryVariables>({
      query,
      variables: varBuilder({ ...queryVariables }),
      fetchPolicy: 'network-only',
    });

    if (disposed) return;

    const { data: queryData, error: queryError } = result;

    const extractedQueryData = (queryData as QueryType)[key] as QueryResult;

    const parsed = parseEntities(extractedQueryData);
    const merged = merge({ existing: data.data.value, newData: parsed });

    // Don't use page info from result to overwrite page info of current result.
    // The order of newly fetched data will mostly differ from the normal one.
    data.error.value = queryError;
    data.data.value = merged;
  };

  const reset = () => {
    if (disposed) return;
    data.data.value = [];
    endCursor = undefined;
  };

  return {
    fetchNext,
    reset,
    refetch,
    fetchNew,
    dispose() {
      disposed = true;
    },
  };
}
