import { useMutation, useQuery, UseQueryOptions } from '@vue/apollo-composable';
import { computed, Ref, unref } from 'vue';

import { gql } from '@/graphql/__generated__/gql';
import {
  MembershipsQuery,
  MembershipsQueryVariables,
  OwnUserQuery,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserFragment,
  UserQuery,
  UserQueryVariables,
} from '@/graphql/__generated__/graphql';
import MembershipsAllQuery from '@/graphql/membership/All.gql';
import UserCreate from '@/graphql/users/Create.gql';
import UserDetailQuery from '@/graphql/users/Detail.gql';
import UserFragmentDocument from '@/graphql/users/Fragment.gql';
import OwnUserDetailQuery from '@/graphql/users/OwnUser.gql';
import UserUpdate from '@/graphql/users/Update.gql';
import { sortObjectsByKey } from '@/helpers/utils/arrays';
import { omitKeys } from '@/helpers/utils/objects';
import { AppApolloClient } from '@/interfaces/graphql';
import { UserRepository } from '@/interfaces/repositories';
import {
  Entity,
  MutationResult,
  QueryAllResult,
  QueryResult,
} from '@/interfaces/repositories/base';
import {
  FindAllUsersVariables,
  UpdateUserVariables,
  UserAccountStatus,
  UserInvitation,
  UserMutateReturn,
  UserPreview,
} from '@/interfaces/repositories/users';
import { useDefaultQueryOnEntity } from '@/repositories/utils/defaults';
import { useCalledIndicator } from '@/repositories/utils/helper';

import { FragmentName, getDataId, getOptimisticResponse, NodeName } from './utils/cache';
import { flattenNodeConnection, useFetchAllResult } from './utils/fetchAll';

export class GraphQLUserRepository implements UserRepository {
  public constructor(private client: AppApolloClient) {}

  public useCreate(
    input: Ref<RegisterUserMutationVariables['input']>,
  ): MutationResult<RegisterUserMutationVariables['input'], UserMutateReturn> {
    const variables: Ref<RegisterUserMutationVariables> = ref({ input: { ...input.value } });

    const { mutate, error, loading } = useMutation<
      RegisterUserMutation,
      RegisterUserMutationVariables
    >(UserCreate, () => ({
      variables: variables.value,
    }));

    return {
      mutate: (data?: RegisterUserMutationVariables['input']): Promise<UserMutateReturn> => {
        if (data) {
          variables.value = {
            input: {
              ...(variables.value.input ?? {}),
              ...data,
            },
          };
        }

        return mutate().then((result) => ({
          id: result?.data?.registerUser?.user?.id ?? '',
        }));
      },
      loading,
      error,
    };
  }

  public useUpdate(
    _id: string,
    vars: Ref<UpdateUserVariables>,
  ): MutationResult<UpdateUserVariables, UserMutateReturn> {
    const { mutate, error, loading } = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
      UserUpdate,
      () => ({
        variables: omitKeys(vars.value, ['previewUrl']),
      }),
    );

    return {
      mutate: (data?: UpdateUserVariables): Promise<UserMutateReturn> => {
        const ownId = unref(_id) ?? '';

        const mergedData = { ...vars.value, ...(data || {}) };

        // read fragment of own object from cache to get additional data, that never gets updated (e.g. tenant in this case)
        const user = this.client.readFragment({
          id: getDataId(NodeName.USER, ownId),
          fragment: UserFragmentDocument,
          fragmentName: FragmentName.USER,
        });

        const optimisticResponse = getOptimisticResponse<UpdateUserMutation>(() => {
          return {
            updateUser: {
              __typename: 'UpdateUserPayload',
              user: {
                ...user,
                ...mergedData,
                profilePicture: {
                  __typename: 'UserProfilePictureNode',
                  image: { __typename: 'FileContent', url: mergedData.previewUrl ?? '' },
                  imageThumbnail: { __typename: 'FileContent', url: mergedData.previewUrl ?? '' },
                  lowResImage: { __typename: 'FileContent', url: mergedData.previewUrl ?? '' },
                },
              },
            },
          };
        });
        const mutated = mutate(data, {
          optimisticResponse,
        }).then((result) => result?.data?.updateUser?.user) as Promise<UserMutateReturn>;

        return mutated;
      },
      loading,
      error,
    };
  }

  public fetchOne(id: string): QueryResult<UserFragment | null, Entity> {
    const variables: UserQueryVariables = {
      id,
    };

    const { result, loading, error, refetch } = useDefaultQueryOnEntity<
      UserQuery,
      UserQueryVariables
    >(UserDetailQuery, variables);

    const user = computed(() => result.value?.user ?? null);

    const { called } = useCalledIndicator(result);

    return {
      result: user,
      loading,
      error,
      refetch,
      called,
    };
  }

  public fetchOwn(options?: UseQueryOptions): QueryResult<UserFragment | undefined | null> {
    const { result, loading, error, refetch } = useQuery<OwnUserQuery>(
      OwnUserDetailQuery,
      undefined,
      {
        // no need to re-execute the query automatically, as the user won't change, and if it does, it will be
        // refetched manually
        fetchPolicy: 'cache-first',
        ...((options ?? {}) as Dictionary),
      },
    );

    const user = computed(() => result.value?.ownUser ?? null);

    const { called } = useCalledIndicator(result);

    return { result: user, loading, error, refetch, called };
  }

  public async fetchInvitation(invitationToken: string): Promise<UserInvitation | undefined> {
    const query = gql(/* GraphQL */ `
      query UserInvitation($invitationToken: String!) {
        userInvitation(invitationToken: $invitationToken) {
          signUpConfiguration {
            domain
            enforceSSO
          }
        }
      }
    `);
    const { data, error, errors } = await this.client.query({
      query,
      variables: { invitationToken },
      fetchPolicy: 'network-only',
    });

    if (error) throw error;
    if (errors) throw errors;

    if (!data.userInvitation) return undefined;

    return {
      configuration: {
        enforceMSSO: data.userInvitation.signUpConfiguration?.enforceSSO ?? false,
        domain: data.userInvitation.signUpConfiguration?.domain ?? undefined,
      },
    };
  }

  public async getOwnAccountStatus(): Promise<UserAccountStatus> {
    const query = gql(/* GraphQL */ `
      query ownUserExists {
        ownUser {
          id
          isActive
        }
      }
    `);

    const { data, error, errors } = await this.client.query({
      query,
      variables: {},
      fetchPolicy: 'network-only',
    });

    if (error) throw error;
    if (errors) throw errors;

    if (!data.ownUser) return UserAccountStatus.Unknown;

    if (data.ownUser.isActive) return UserAccountStatus.Active;

    return UserAccountStatus.Invited;
  }

  public fetchAll(filterArgs: Ref<FindAllUsersVariables>): QueryAllResult<UserPreview> {
    const {
      result: rawResult,
      loading,
      error,
      refetch,
    } = useQuery<MembershipsQuery, MembershipsQueryVariables>(
      MembershipsAllQuery,
      () => ({
        ...filterArgs.value,
        tenant: filterArgs.value.tenantId,
      }),
      {
        fetchPolicy: 'cache-and-network',
      },
    );

    const result = useFetchAllResult<
      MembershipsQuery,
      NonNullable<MembershipsQuery['memberships']>,
      UserPreview
    >(rawResult, 'memberships', (data) =>
      sortObjectsByKey(
        flattenNodeConnection(data)
          .map((membership) => membership.user)
          .filter((user) => !!user) as UserPreview[],
        'lastName',
      ),
    );

    const { called } = useCalledIndicator(rawResult);

    return { ...result, loading, error, refetch, called };
  }
}
