export function getOffsetHeightForHtml(html: string, width?: number): number {
  const wrapper = document.createElement('div');
  if (width) {
    wrapper.style.width = `${width}px`;
  }
  wrapper.style.position = 'fixed';
  wrapper.style.left = '200vw';
  wrapper.style.top = '200vh';
  wrapper.style.visibility = 'hidden';
  wrapper.innerHTML = html;
  document.body.appendChild(wrapper);
  const height = wrapper.offsetHeight;
  document.body.removeChild(wrapper);
  return height;
}

export function isElementPartlyInViewport(
  element: HTMLElement,
  viewport?: { width: number; height: number },
): boolean {
  const rect = element.getBoundingClientRect();
  const size = viewport || { width: window.innerWidth, height: window.innerHeight };
  const partlyVisibleX = rect.left < size.width && rect.right > 0;
  const partlyVisibleY = rect.top < size.height && rect.bottom > 0;
  return partlyVisibleX && partlyVisibleY;
}

export function areRectsOverlapping(rect1: DOMRect, rect2: DOMRect) {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}

export function isRectLeftOfOtherRect(rect1: DOMRect, rect2: DOMRect) {
  return rect1.left < rect2.left;
}
