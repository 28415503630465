enum BaseRouteName {
  PickTenant = 'PickTenant',
}

export enum UnauthenticatedRouteName {
  Login = 'Login',
  ResetPassword = 'ResetPassword',
  SetPassword = 'SetPassword',
  SignUp = 'SignUp',
}

export enum TenantRouteName {
  AllProjects = 'LeanProjectsAll',
  Details = 'CompanyDetails',
  TenantTrades = 'TenantTrades',
  TenantTradeSequences = 'TenantTradeSequences',
  ProjectStructureTemplates = 'ProjectStructureTemplates',
  ProjectTemplates = 'ProjectTemplates',
}

export enum UserRouteName {
  Edit = 'UserEdit',
  NoMemberships = 'NoMemberships',
}

export enum LeanRouteName {
  Dashboard = 'LeanDashboard',
  Participants = 'Participants',
  ParticipantsContributors = 'ParticipantsContributors',
  ParticipantsManagement = 'ParticipantsManagement',
  Schedule = 'LeanProjectSchedule',
  Tickets = 'Tickets',
  PhotoDocumentation = 'PhotoDocumentation',
}

export enum ProjectTemplateRouteName {
  Schedule = 'ProjectTemplateSchedule',
}

export const Routes = {
  Unauthenticated: UnauthenticatedRouteName,
  User: UserRouteName,
  Tenant: TenantRouteName,
  Base: BaseRouteName,
  Lean: LeanRouteName,
  ProjectTemplate: ProjectTemplateRouteName,
};

type RoutesType = typeof Routes;
type RoutesKey = keyof RoutesType;

const checkRoutesContain = (
  routes: RoutesType[RoutesKey],
  routeName: string | symbol | null | undefined,
) => {
  if (!routeName) return false;
  // we don't use symbols, but vue router does support them
  return typeof routeName !== 'symbol' && (Object.values(routes) as string[]).includes(routeName);
};

export const useRouteNames = () => ({ Routes, checkRoutesContain });
