import { App } from 'vue';

/**
 * Messages that parent sends to child frame.
 */
type ParentToFrameMessage =
  | {
      type: 'DISPLAY_VERSION';
      data: {
        versionId: string;
        projectId: string;
      };
    }
  | {
      /**
       * Send by parent when iframe finished loading .
       */
      type: 'PARENT_HELLO';
    };

/**
 * Messages that frame sends to parent.
 */
type FrameToParentMessage =
  | {
      /**
       * Send by iframe when schedule of project finished loading.
       */
      type: 'SCHEDULE_LOADED';
    }
  | {
      /**
       * Send by iframe when version finished loading.
       */
      type: 'VERSION_LOADED';
    };

export type GlobalMessage = ParentToFrameMessage | FrameToParentMessage;

const injectKey = Symbol('GlobalMessageListener');

export type ParentMessageListener = (message: GlobalMessage) => void;

export class GlobalMessageListener {
  /**
   * Port is set when frame receives message from parent.
   */
  private parentPort: MessagePort | undefined;

  private listeners: ParentMessageListener[] = [];

  public listen(): void {
    window.addEventListener('message', (event) => {
      if (!event.ports.length) return;

      const parentMessage = event.data as Partial<GlobalMessage>;
      if (typeof parentMessage === 'object' && parentMessage?.type === 'PARENT_HELLO') {
        const [ownPort] = event.ports;
        ownPort.onmessage = (m) => {
          const message = m.data as GlobalMessage;
          this.listeners.forEach((listener) => listener(message));
        };
        this.parentPort = ownPort;
      }
    });
  }

  public onScheduleLoad(): void {
    if (!this.parentPort) {
      return;
    }
    const message: GlobalMessage = { type: 'SCHEDULE_LOADED' };
    this.parentPort.postMessage(message);
  }

  public onVersionLoad(): void {
    if (!this.parentPort) {
      return;
    }
    const message: GlobalMessage = { type: 'VERSION_LOADED' };
    this.parentPort.postMessage(message);
  }

  public addListener(callback: ParentMessageListener): void {
    this.listeners.push(callback);
  }

  public removeListener(callback: ParentMessageListener): void {
    this.listeners = this.listeners.filter((l) => l !== callback);
  }
}

export function installGlobalMessageListener(app: App) {
  const listener = new GlobalMessageListener();
  app.provide(injectKey, listener);
  return listener;
}

export function useGlobalMessageListener(): GlobalMessageListener {
  return inject(injectKey) as GlobalMessageListener;
}

/**
 * Sets up listener that is invoked for every message that is received
 * from current frame's parent.
 * @param listener
 */
export function useParentMessageListener(listener: ParentMessageListener) {
  const globalListener = useGlobalMessageListener();

  onMounted(() => {
    globalListener.addListener(listener);
  });

  onUnmounted(() => {
    globalListener.removeListener(listener);
  });
}
