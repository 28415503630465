import { max, min } from 'date-fns';
import { Composer } from 'vue-i18n';

import { scheduleSelectors } from '@/common/selectors/schedule';
import { Collision, useCollisionStore } from '@/features/collisions';
import { CollisionRowScrollButtonClickedEvent } from '@/features/collisions/collisionAnalytics';
import { LoggingService } from '@/interfaces/services';
import Button from '@/jsx/components/Button';
import Icon from '@/jsx/components/Icon';
import { h } from '@/jsx/jsxFactory';
import { JIMO_POKE_IDS } from '@/plugins/jimo/jimo';
import { getScheduler } from '@/services/store/integrations/scheduler';

type CollisionSummaryNavigatorProps = {
  i18n: Composer;
  collisionStore: ReturnType<typeof useCollisionStore>;
  collisionsBeforeRange: Collision[];
  collisionsAfterRange: Collision[];
  loggingService: LoggingService;
};

// Remove when https://github.com/koppla-tech/koppla-webapp/pull/9706 is merged
const icons = {
  collision:
    'M7.5 22L10 13H2L8 8L6.5 3L11.5 5.5L17 1.5L15.5 7.5H21.6583C21.9193 7.5 22.0498 7.5 22.1065 7.55023C22.1557 7.59376 22.1804 7.65867 22.1725 7.72388C22.1635 7.79913 22.066 7.88581 21.8709 8.05917L18 11.5L19 15H14.5L7.5 22Z',
  arrowNarrowLeft: 'M20 12H4M4 12L10 18M4 12L10 6',
  arrowNarrowRight: 'M4 12H20M20 12L14 6M20 12L14 18',
};

export function CollisionSummaryNavigator({
  i18n,
  collisionStore,
  collisionsBeforeRange,
  collisionsAfterRange,
  loggingService,
}: CollisionSummaryNavigatorProps): Element {
  const renderJumpButton = (
    collisions: Collision[],
    icon: string,
    testId: string,
    onClick: () => void,
  ) =>
    collisions.length ? (
      <Button size="xs" className="tw-p-2" onClick={onClick} dataTestid={testId} destructive>
        <Icon outlined={false} size={16} svg={true} strokeWidth={2} icon={icon} />
        {collisions.length.toString()}
      </Button>
    ) : null;

  const jumpToPreviousCollision = () => {
    loggingService.trackEvent(new CollisionRowScrollButtonClickedEvent({ value: 'previous' }));
    getScheduler()?.scrollToDate(getLastDate(collisionsBeforeRange), { block: 'center' });
  };

  const jumpToNextCollision = () => {
    loggingService.trackEvent(new CollisionRowScrollButtonClickedEvent({ value: 'next' }));
    getScheduler()?.scrollToDate(getFirstDate(collisionsAfterRange), { block: 'center' });
  };

  return (
    <div
      id={JIMO_POKE_IDS.COLLIISION_ROW_LABEL}
      class="tw-flex tw-items-center tw-justify-center tw-h-[38px] tw-w-full tw-bg-attention-25 tw-border-t tw-border-t-grey-100 tw-px-2"
      data-testid={scheduleSelectors.collisionsRow.cornerElementId}
    >
      <div class="tw-flex tw-flex-grow tw-basis-full tw-justify-start">
        {renderJumpButton(
          collisionsBeforeRange,
          icons.arrowNarrowLeft,
          scheduleSelectors.collisionsRow.jumpToPreviousButton,
          jumpToPreviousCollision,
        )}
      </div>
      <div class="tw-flex tw-items-center tw-justify-center tw-flex-grow tw-ds-text-md--semibold tw-text-attention-500 ">
        <Icon
          icon={icons.collision}
          outlined={true}
          svg={true}
          size={24}
          className="tw-text-attention-500"
        />
        <span class="tw-text-attention-500 tw-mx-1">
          {collisionStore.collisions.size.toString()}
        </span>
        <span>{i18n.t('collisions.name', collisionStore.collisions.size)}</span>
      </div>
      <div class="tw-flex tw-flex-grow tw-basis-full tw-justify-end">
        {renderJumpButton(
          collisionsAfterRange,
          icons.arrowNarrowRight,
          scheduleSelectors.collisionsRow.jumpToNextButton,
          jumpToNextCollision,
        )}
      </div>
    </div>
  );
}

const getLastDate = (collisions: Collision[]): Date =>
  max(collisions.map((collision: Collision) => collision.date));

const getFirstDate = (collisions: Collision[]): Date =>
  min(collisions.map((collision: Collision) => collision.date));
