import { Entity, MilestoneEntity, PartialEntity } from '@/common/types';
import {
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  OperationInputType,
  RemoteProjectChangeEvent,
} from '@/features/realTimeCollaboration/types';
import { getEventCreationInformation } from '@/features/realTimeCollaboration/utils';

import { MilestoneType } from '../types';

export function convertMilestoneCreateOperationInput(
  input: OperationInputType<'CreateMilestones'>,
): MilestoneEntity[] {
  return input.map((m) => ({
    ...m,
    date: new SchedulingDate(m.date),
    wbsSection: m.wbsSectionId ? { id: m.wbsSectionId } : null,
    isFixed: m.type === MilestoneType.FIXED,
    type: m.type as MilestoneType,
    acceptanceCriteria: m.criteria ?? [],
    completedAt: null,
  }));
}

export function convertMilestoneUpdateOperationInput(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  input: (OperationInputType<'UpdateMilestones'>[0] &
    Partial<OperationInputType<'UpdateMilestoneStatus'>>)[],
): PartialEntity<MilestoneEntity>[] {
  const { createdBy } = getEventCreationInformation(event);

  return input.map((m) => ({
    id: m.id,
    ...(m.color !== undefined ? { color: m.color ?? '' } : {}),
    ...(m.date != undefined ? { date: new SchedulingDate(m.date) } : {}),
    ...(m.name !== undefined ? { name: m.name ?? '' } : {}),
    ...(m.wbsSectionId !== undefined
      ? { wbsSection: typeof m.wbsSectionId === 'string' ? { id: m.wbsSectionId } : null }
      : {}),
    ...(m.type !== undefined
      ? { isFixed: m.type === MilestoneType.FIXED, type: m.type as MilestoneType }
      : {}),
    ...(m.criteria !== undefined ? { acceptanceCriteria: m.criteria ?? [] } : {}),
    ...(m.completedAt !== undefined
      ? { completedAt: typeof m.completedAt === 'string' ? new Date(m.completedAt) : null }
      : {}),
    ...(m.completedAt !== undefined
      ? { completedBy: typeof m.completedAt === 'string' ? createdBy : null }
      : {}),
  }));
}

export function convertMilestoneDeleteOperationInput(
  input: OperationInputType<'RemoveScheduleNodes'>,
): Entity[] {
  return input.milestones?.map((id) => ({ id })) ?? [];
}
