import { SchedulerEventModel } from '@bryntum/schedulerpro';

import { useMilestoneStore } from '@/features/milestones';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { Entity } from '@/interfaces/repositories/base';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { DryingBreakEventParser } from '@/services/store/schedule/parsers';
import { getPlaceholderEventId } from '@/services/store/schedule/parsers/base';
import {
  CloseSchedulerSidebarOptions,
  NewSchedulerEvent,
  OpenSchedulerSidebarOptions,
  SchedulerDependency,
  SchedulerSidebarComponent,
  SchedulerSidebarState,
} from '@/services/store/schedule/types';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';

export function openSidebar(
  store: ScheduleStore,
  { event, dependency, replace, component, payload }: OpenSchedulerSidebarOptions,
): void {
  const newSidebarOpenedCompletely = !store.sidebar && component !== null;
  if (newSidebarOpenedCompletely) {
    store.sidebarAnimating = true;
    setTimeout(() => {
      store.sidebarAnimating = false;
    }, 300);
  }
  if (component != null && payload != null) {
    store.sidebar = {
      [component]: payload,
    };
    removePlaceholderEventIfNecessary(store);
    return;
  }

  if (replace && store.sidebar) {
    const objectId = getOpenSidebarObjectId(store.sidebar);
    component = getOpenSidebarComponent(store.sidebar) ?? undefined;

    if (objectId) {
      event = store.entities.events.get(objectId);
      dependency = store.entities.dependencies.get(objectId);
    }

    if (
      objectId &&
      !event &&
      !dependency &&
      !store.loading &&
      component !== SchedulerSidebarComponent.CALENDAR_EDIT
    ) {
      store.closeSidebar();
      return;
    }
  }

  if (event) {
    store.sidebar = openFromEvent(component, event);
    if (!getOpenSidebarObjectId(store.sidebar)) {
      store.closeSidebar();
      return;
    }
  }
  if (dependency) {
    store.sidebar = openFromDependency(component, dependency);
    if (!getOpenSidebarObjectId(store.sidebar)) {
      store.closeSidebar();
      return;
    }
  }
  removePlaceholderEventIfNecessary(store);
}

export function closeSidebar(store: ScheduleStore, options?: CloseSchedulerSidebarOptions): void {
  store.sidebarAnimating = true;
  setTimeout(() => {
    store.sidebarAnimating = false;
  }, 300);
  store.sidebar = null;
  store.removePlaceholderEvent();
  store.utils.disableEventInteractions = false;
  store.utils.disableSectionInteractions = false;
  store.utils.isSidebarOpenByKeyboardShortcut = false;

  if (!options?.preserveEventSelection) {
    store.utils.allowEventDeselection = true;
    getScheduler()?.clearEventSelection();
  }
}

export function getOpenSidebarComponent(
  sidebar: SchedulerSidebarState | null,
): SchedulerSidebarComponent | null {
  if (!sidebar) return null;
  return parseInt(Object.keys(sidebar ?? {})[0], 10) as SchedulerSidebarComponent;
}

export function getOpenSidebarObjectId(sidebar: SchedulerSidebarState | null): string | null {
  if (!sidebar) return null;
  const component = getOpenSidebarComponent(sidebar);
  if (component == null) return null;
  return (sidebar![component] as Entity)?.id ?? null;
}

function openFromEvent(
  component: SchedulerSidebarComponent | undefined,
  event: NewSchedulerEvent,
): SchedulerSidebarState | null {
  if (event.id === getPlaceholderEventId() && component != null) {
    return {
      [component]: {
        id: event.id,
        startDate: event.startDate,
        endDate: event.endDate,
        resourceId: event.resourceId,
        isFixed: event.isFixed,
        creationMethod: event.creationMethod,
      },
    };
  }
  if (event.entity === NodeName.PAUSE) {
    const pauseStore = usePauseStore();
    return {
      [component ?? SchedulerSidebarComponent.PAUSE_DETAILS]: pauseStore.pauses.get(event.id)!,
    };
  }
  if (event.entity === NodeName.MILESTONE) {
    const milestoneStore = useMilestoneStore();
    return {
      [component ?? SchedulerSidebarComponent.MILESTONE_DETAILS]: milestoneStore.milestones.get(
        event.id,
      )!,
    };
  }
  if (event.entity === NodeName.ORDER) {
    const orderStore = useOrderStore();
    return {
      [component ?? SchedulerSidebarComponent.ORDER_DETAILS]: orderStore.orders.get(event.id)!,
    };
  }

  return null;
}

function openFromDependency(
  component: SchedulerSidebarComponent | undefined,
  dependency: SchedulerDependency,
): SchedulerSidebarState | null {
  let fromEvent = dependency.from;
  const milestoneStore = useMilestoneStore();
  const orderStore = useOrderStore();
  const scheduler = getScheduler()!;
  const scheduleAppearanceStore = useScheduleAppearanceStore();

  if (milestoneStore.milestones.get(fromEvent) !== undefined) {
    const schedulerMilestone = getScheduler()?.eventStore.getById(fromEvent) as SchedulerEventModel;
    scheduler.selectEvent(schedulerMilestone, false);

    scheduleAppearanceStore.setEventIdToHighlightRelationsFor(fromEvent);

    return {
      [component ?? SchedulerSidebarComponent.MILESTONE_DETAILS]: {
        ...milestoneStore.milestones.get(fromEvent)!,
        dependencyToBeExpanded: dependency.id,
      },
    };
  }
  if (
    orderStore.orders.get(fromEvent) !== undefined ||
    orderStore.orders.get(DryingBreakEventParser.dryingBreakIdToOrderId(fromEvent)) !== undefined
  ) {
    let order = orderStore.orders.get(fromEvent);
    if (order === undefined) {
      fromEvent = DryingBreakEventParser.dryingBreakIdToOrderId(fromEvent);
      order = orderStore.orders.get(fromEvent);
    }
    const scheduleOrder = getScheduler()?.eventStore.getById(fromEvent) as SchedulerEventModel;
    scheduler.selectEvent(scheduleOrder, false);

    scheduleAppearanceStore.setEventIdToHighlightRelationsFor(fromEvent);

    return {
      [component ?? SchedulerSidebarComponent.ORDER_DETAILS]: {
        ...order!,
        dependencyToBeExpanded: dependency.id,
      },
    };
  }
  return null;
}

function removePlaceholderEventIfNecessary(store: ScheduleStore): void {
  if (
    store.entities.events.has(getPlaceholderEventId()) &&
    getOpenSidebarObjectId(store.sidebar) !== getPlaceholderEventId()
  ) {
    store.removePlaceholderEvent();
  }
}
