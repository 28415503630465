import { OrderEntity, OrderStatusEntity, PartialEntity } from '@/common/types';
import { getEventCreationInformation } from '@/features/realTimeCollaboration/utils';
import { convertOrderStatusToStatusReport } from '@/helpers/orders/status';

import {
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  OperationInputType,
  RemoteProjectChangeEvent,
} from '../realTimeCollaboration/types';

export function convertOrderStatusReportCreateOperationInput(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  input: OperationInputType<'CreateOrderStatusReports'>,
): {
  addedOrderStatusReports: OrderStatusEntity[];
  updatedOrders: PartialEntity<OrderEntity>[];
} {
  const { createdBy, createdByContributorGroup } = getEventCreationInformation(event);

  const addedOrderStatusReports: OrderStatusEntity[] = [];
  const updatedOrders: PartialEntity<OrderEntity>[] = [];

  for (const orderCreateStatusInput of input) {
    const newStatusReport = convertOrderStatusToStatusReport(orderCreateStatusInput.status);

    addedOrderStatusReports.push({
      id: orderCreateStatusInput.id,
      orderId: orderCreateStatusInput.orderId,
      status: newStatusReport,
      progress: orderCreateStatusInput.progress,
      reason: orderCreateStatusInput.reason,
      comment: orderCreateStatusInput.comment,
      reportedAt: new Date(orderCreateStatusInput.reportedAt),
      createdBy,
      createdByContributorGroup,
    });
    updatedOrders.push({
      id: orderCreateStatusInput.orderId,
      status: newStatusReport,
      progress: orderCreateStatusInput.progress,
      finishedAt: newStatusReport === 'DONE' ? new Date(orderCreateStatusInput.reportedAt) : null,
    });
  }

  return {
    addedOrderStatusReports,
    updatedOrders,
  };
}
