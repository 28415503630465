import { defineStore } from 'pinia';
import { Composer } from 'vue-i18n';
import { Router } from 'vue-router';

import { useFeatureAccessStore } from '@/common/featureAccessStore';
import { useScheduleFilterStore } from '@/features/filter-and-export';
import { LoggingService } from '@/interfaces/services';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import {
  addPlaceholderEvent,
  applyFilter,
  closeSidebar,
  copyEvents,
  cutEvents,
  deleteEvents,
  destroy,
  initialize,
  openSidebar,
  pasteEvents,
  removePlaceholderEvent,
  setHolidays,
  updatePlaceholderEvent,
} from '@/services/store/schedule/actions';
import { openObject } from '@/services/store/schedule/actions/initialize/base';
import { filterAndSetMultiSelectedEvents } from '@/services/store/schedule/actions/select';
import { getFeatures, setConfig } from '@/services/store/schedule/config';
import { ResourceParser, TimeRangeParser } from '@/services/store/schedule/parsers';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import {
  OpenSchedulerPopupOptions,
  SchedulerPopupComponent,
  useSchedulePopupStore,
} from '@/services/store/schedulePopup';
import { AvailableHolidayCountry, AvailableHolidayState } from '@/utils/holidayList';

import {
  collapseResource,
  expandResource,
  refreshScheduleUI,
} from '../../../features/schedule/bryntum/schedulerInteractions';
import {
  CloseSchedulerSidebarOptions,
  OpenSchedulerSidebarOptions,
  SchedulerClipboard,
  SchedulerClipboardContext,
  SchedulerConfig,
  SchedulerDependency,
  SchedulerEvent,
  SchedulerFeaturesRef,
  SchedulerPlaceholderEventData,
  SchedulerRecurrentTimeRange,
  SchedulerResource,
  SchedulerSidebarState,
} from './types';

type CopyPasteReturn = Partial<Record<NodeName, number>>;

export enum Sidebars {
  CLOSED = 'CLOSED',
  TEMPORARY_FILTER = 'TEMPORARY_FILTER',
  PERMANENT_FILTER = 'PERMANENT_FILTER',
  TRADE_SEQUENCES = 'TRADE_SEQUENCES',
  EXPORT = 'EXPORT',
  COLLISIONS = 'COLLISIONS',
  REST = 'REST',
}

export const getInitialEntityState = () => {
  const weekends = new TimeRangeParser().parseRecurringWeekends();
  const mainResource = new ResourceParser().parseMainResource();
  const emptyResource = new ResourceParser().parseEmptyResource();

  const resources: [string, SchedulerResource][] = [
    [mainResource.id, mainResource],
    [emptyResource.id, emptyResource],
  ];

  return {
    events: new Map<string, SchedulerEvent>(),
    resources: new Map<string, SchedulerResource>(resources),
    timeRanges: new Map<string, SchedulerRecurrentTimeRange>([[weekends.id, weekends]]),
    resourceTimeRanges: new Map<string, SchedulerRecurrentTimeRange>(),
    dependencies: new Map<string, SchedulerDependency>(),
    sectionSummaryEvents: new Map<string, SchedulerEvent>(),
  };
};

const getInitialUtilsState = () => ({
  isDraggingDependency: false,
  allowEventDeselection: false,
  disableEventInteractions: false,
  disableSectionInteractions: false,
  hasEventSelection: false,
  // map of <EventId, EventEntityType>
  multiSelectedEvents: new Map<string, NodeName>(),
  isMultiSelecting: false,
  isDragSelecting: false,
  scheduleContextMarker: null as { startDate: Date; endDate: Date; resourceId: string } | null,
  showDragCursor: false,
  squashPendingCommits: false,
  unwatchers: [] as (() => void)[],
  lastProjectId: '',
  isSidebarOpenByKeyboardShortcut: false,
  isAltKeyDown: false,
  isDraggingEvent: false,
});

export const useScheduleStore = defineStore('schedule-store', {
  state: () => ({
    tenantId: '',
    projectId: '',
    readonly: true,
    loading: true,
    initialized: false,
    isSwitchingScheduler: false,
    entities: getInitialEntityState(),
    sidebar: null as SchedulerSidebarState | null,
    sidebarAnimating: false,
    utils: getInitialUtilsState(),
    clipboard: null as SchedulerClipboard | null,
    currentSidebar: Sidebars.CLOSED as Sidebars | null,
    dateRange: null as { startDate: Date; endDate: Date } | null,
  }),
  getters: {
    isMultiSelectToolbarOpen: (state) => state.utils.multiSelectedEvents.size > 0,
    isAltKeyDown: (state) => state.utils.isAltKeyDown,
  },
  actions: {
    clearClipboard(): void {
      if (!this.clipboard) return;

      this.clipboard = null;
      refreshScheduleUI(getScheduler());
      this.closePopup();
    },
    updateClipboard(events: string[]): void {
      if (!this.clipboard) return;

      this.clipboard.data = events;
      refreshScheduleUI(getScheduler());
    },
    updateClipboardContext(context: SchedulerClipboardContext | null): void {
      if (!this.clipboard) return;

      this.clipboard.context = context;
    },
    initialize(objectId: string, router: Router): void {
      initialize.bind(this, objectId, router)();
    },
    destroy(): void {
      destroy(this);
    },
    setHolidays(country: keyof AvailableHolidayCountry, state: keyof AvailableHolidayState): void {
      setHolidays(this, country, state);
    },
    setConfig(i18n: Composer, loggingService: LoggingService): SchedulerConfig {
      return setConfig(this, i18n, loggingService);
    },
    getFeatures(): SchedulerFeaturesRef {
      const featureAccessStore = useFeatureAccessStore();
      return getFeatures(this, featureAccessStore);
    },
    addPlaceholderEvent(data: SchedulerPlaceholderEventData): SchedulerEvent {
      return addPlaceholderEvent(this, data);
    },
    updatePlaceholderEvent(data: SchedulerPlaceholderEventData): SchedulerEvent | null {
      return updatePlaceholderEvent(this, data);
    },
    removePlaceholderEvent(): void {
      removePlaceholderEvent(this);
    },
    openSidebar(data: OpenSchedulerSidebarOptions): void {
      this.currentSidebar = Sidebars.REST;
      openSidebar(this, data);
    },
    closeSidebar(options?: CloseSchedulerSidebarOptions): void {
      this.currentSidebar = Sidebars.CLOSED;
      closeSidebar(this, options);
    },
    openObject(objectId: string, router: Router): void {
      openObject(this, objectId, router);
    },
    openPopup<T extends SchedulerPopupComponent>(data: OpenSchedulerPopupOptions<T>): void {
      useSchedulePopupStore().openPopup(data);
    },
    closePopup(componentToClose?: SchedulerPopupComponent): void {
      useSchedulePopupStore().closePopup(componentToClose);
    },
    filterAndSetMultiSelectedEvents(
      filter: (event: SchedulerEvent) => boolean = () => true,
      selectionOverwrite?: SchedulerEvent[],
    ): void {
      const scheduler = getScheduler();
      if (!scheduler) return;

      const featureAccessStore = useFeatureAccessStore();

      filterAndSetMultiSelectedEvents({
        scheduleStore: this,
        featureAccessStore,
        scheduler,
        filter,
        selectionOverwrite,
      });
    },
    copyEvents(): CopyPasteReturn {
      return copyEvents(this);
    },
    cutEvents(): CopyPasteReturn {
      return cutEvents(this);
    },
    pasteEvents(): CopyPasteReturn {
      return pasteEvents(this);
    },
    deleteEvents(): CopyPasteReturn {
      return deleteEvents(this);
    },
    updateAndApplyFilter(): void {
      useScheduleFilterStore().updateSectionFilterStateWithLatestSections();
      applyFilter();
    },
    applyFilter(): void {
      applyFilter();
    },
    setIsAltKeyDown(state: boolean): void {
      this.utils.isAltKeyDown = state;
    },
    setCollapsedStateForResources(ids: string[], collapsed: boolean): void {
      if (collapsed) {
        useScheduleAppearanceStore().collapseStateForRows(ids);
      } else {
        useScheduleAppearanceStore().expandStateForRows(ids);
      }
      const updatedResources = new Map(this.entities.resources.entries());
      ids.forEach((id) => {
        const resource = updatedResources.get(id);
        if (!resource) return;
        if (collapsed) {
          collapseResource(getScheduler(), id);
        } else {
          expandResource(getScheduler(), id);
        }
        updatedResources.set(id, { ...resource, expanded: !collapsed });
      });
      this.entities.resources = updatedResources;
    },
    updateVisibleDateRange(range: { startDate: Date; endDate: Date } | null): void {
      this.dateRange = range;
    },
  },
});

export type ScheduleStore = ReturnType<typeof useScheduleStore>;
