import { addDays, startOfWeek } from 'date-fns';

import { ScheduleConstants } from '@/common/bryntum/constants';
import { useCalendarStore } from '@/features/calendars';
import { DEFAULT_MILESTONE_TYPE } from '@/features/milestones/milestoneUtils';
import { MilestoneType } from '@/features/milestones/types';
import { useLocalOrderSchedulingEngine } from '@/features/schedule';
import { isDayGranularity, isMinuteGranularity } from '@/features/schedule/bryntum/presets';
import {
  getCurrentViewPreset,
  scrollToDate,
} from '@/features/schedule/bryntum/schedulerInteractions';
import { START_OF_WEEK } from '@/helpers/dates/config';
import { MINUTES_PER_DAY } from '@/helpers/utils/timeConstants';
import { NodeName } from '@/repositories/utils/cache';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { useScheduleStore } from '@/services/store/schedule';
import { getMainResourceId } from '@/services/store/schedule/parsers/base';
import {
  MilestoneCreationMethod,
  NewSchedulerEvent,
  OrderCreationMethod,
  SchedulerSidebarComponent,
} from '@/services/store/schedule/types';

const SIDEBAR_OPENNING_DELAY = 300;

export const createOrder = (
  date: SchedulingDate,
  resourceId: string,
  creationMethod: OrderCreationMethod,
): void => {
  const scheduleStore = useScheduleStore();
  const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
  const calendar = useCalendarStore().getDefaultCalendar();

  const scheduler = getScheduler();
  const currentViewPreset = getCurrentViewPreset(scheduler);
  const canPlanOnDayBasis =
    isDayGranularity(currentViewPreset) || isMinuteGranularity(currentViewPreset);

  const startDate = localOrderSchedulingEngine.utils.getValidStartDate(
    canPlanOnDayBasis ? date : startOfWeek(date, { weekStartsOn: START_OF_WEEK }),
    calendar.value.id,
  );
  const duration =
    canPlanOnDayBasis || calendar.value.minutesPerDay !== MINUTES_PER_DAY
      ? (calendar.value.minutesPerDay || MINUTES_PER_DAY) *
        ScheduleConstants.DEFAULT_EVENT_DURATION_IN_DAYS
      : ScheduleConstants.DEFAULT_EVENT_DURATION_IN_DAYS_NON_DAY_VIEW * MINUTES_PER_DAY;
  const endDate = localOrderSchedulingEngine.utils.getValidEndDate(
    startDate,
    calendar.value.id,
    duration,
  );

  const placeholderEvent = scheduleStore.addPlaceholderEvent({
    startDate,
    endDate,
    resourceId,
    calendarId: calendar.value.id,
    entity: NodeName.ORDER,
  });
  const event: NewSchedulerEvent = {
    ...placeholderEvent,
    creationMethod: creationMethod ?? undefined,
  };
  scheduleStore.openSidebar({
    component: SchedulerSidebarComponent.ORDER_CREATE,
    event,
  });
  // The placeholder event might be hidden behind the sidebar, but since the
  // sidebar takes some time to open, we need to timeout the scrolling
  setTimeout(() => scrollToDate(scheduler, endDate), SIDEBAR_OPENNING_DELAY);
};

export const createMilestone = (
  date: Date,
  resourceId: string,
  creationMethod: MilestoneCreationMethod,
  type: MilestoneType = DEFAULT_MILESTONE_TYPE,
) => {
  const scheduleStore = useScheduleStore();
  const scheduler = getScheduler();

  const placeholderEvent = scheduleStore.addPlaceholderEvent({
    startDate: date,
    endDate: date,
    resourceId,
    entity: NodeName.MILESTONE,
    isFixed: type === MilestoneType.FIXED,
    type,
  });

  const event = { ...placeholderEvent, creationMethod };

  scheduleStore.openSidebar({
    component: SchedulerSidebarComponent.MILESTONE_CREATE,
    event,
  });

  // Delay scrolling to account for sidebar opening time and
  // add an offset to keep the milestone from appearing too close to the sidebar.
  setTimeout(() => {
    scrollToDate(scheduler, date);
  }, SIDEBAR_OPENNING_DELAY);
};

export const createPause = (date: SchedulingDate) => {
  const scheduleStore = useScheduleStore();
  const scheduler = getScheduler();

  const endDate = addDays(date, ScheduleConstants.DEFAULT_EVENT_DURATION_IN_DAYS);
  const placeholderEvent = scheduleStore.addPlaceholderEvent({
    startDate: date,
    endDate,
    resourceId: getMainResourceId(),
    entity: NodeName.PAUSE,
  });
  scheduleStore.openSidebar({
    component: SchedulerSidebarComponent.PAUSE_CREATE,
    event: placeholderEvent,
  });
  // The placeholder event might be hidden behind the sidebar, but since the
  // sidebar takes some time to open, we need to timeout the scrolling
  setTimeout(() => scrollToDate(scheduler, date), SIDEBAR_OPENNING_DELAY);
};
