import { Composer } from 'vue-i18n';

import { scrollToDate } from '@/features/schedule/bryntum/schedulerInteractions';
import { isMacOS } from '@/helpers/utils/os';
import { LoggingService } from '@/interfaces/services';
import Button from '@/jsx/components/Button';
import Tooltip from '@/jsx/components/Tooltip';
import { h } from '@/jsx/jsxFactory';
import { getScheduler } from '@/services/store/integrations/scheduler';

type JumpToTodayButtonProps = {
  i18n: Composer;
  loggingService: LoggingService;
};

export default function JumpToTodayButton({ i18n, loggingService }: JumpToTodayButtonProps) {
  const SHORTCUT_KEY = isMacOS() ? 'keyboard.mac.jumpToToday' : 'keyboard.rest.jumpToToday';

  const onClick = (event: MouseEvent) => {
    scrollToDate(getScheduler(), new Date());
    loggingService.trackEvent(
      new loggingService.AnalyticEventCategories.ScheduleJumpToTodayClickedEvent({
        method: 'click',
      }),
    );
    if (event.target) {
      (event.target as HTMLElement).blur();
    }
  };

  return (
    <div id="jump-to-today-button" class="tw-flex tw-items-center tw-justify-center">
      <Tooltip
        position="bottom"
        disabled={false}
        content={[
          {
            text: i18n.t('objects.leanProject.schedule.jumpToTodayTooltip'),
            shortcut: i18n.t(SHORTCUT_KEY),
          },
        ]}
      >
        <Button
          className="tw-min-w-[120px]"
          onClick={onClick}
          type="button"
          hierarchy="secondary"
          size="lg"
        >
          {i18n.t('objects.leanProject.schedule.jumpToToday')}
        </Button>
      </Tooltip>
    </div>
  );
}
