import { ExternalProjectDataChange } from '@/features/realTimeCollaboration/types';
import { ProjectContributorGroupFragment } from '@/graphql/__generated__/graphql';

/**
 * Creates a fake project contributor group change event that is necessary to update SENG when a project contributor group is changed for the local user.
 * @param newState
 * @returns
 */
export function createProjectContributorGroupChangeEvent(
  newState: Map<string, ProjectContributorGroupFragment>,
): ExternalProjectDataChange {
  return {
    name: 'ProjectContributorGroupChange',
    data: {
      // NOTE: Not used rn, make sure to fill in if needed
      projectId: '',
      contributorGroups: Array.from(newState.values()).map((group) => ({
        ...group,
        tenantTradeVariationAssignments: group.tenantTradeVariationAssignments.map(
          (assignment) => ({
            ...assignment,
            tenantTradeVariationId: assignment.tenantTradeVariation.id,
          }),
        ),
        memberships: group.memberships.map((membership) => ({
          ...membership,
          userId: membership.user.id,
        })),
      })),
      timestamp: Date.now(),
    },
  };
}
