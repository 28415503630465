import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const fetchProjectTemplateQuery = gqlFn(/* GraphQL */ `
  query ProjectTemplate($id: ID!) {
    projectTemplate(id: $id) {
      ...ProjectTemplate
    }
  }
`);
export const fetchProjectTemplatesQuery = gqlFn(/* GraphQL */ `
  query ProjectTemplates($tenant: ID!) {
    projectTemplates(tenant: $tenant) {
      edges {
        node {
          ...ProjectTemplatePreview
        }
      }
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectTemplateFragment = gql`
  fragment ProjectTemplate on ProjectTemplateNode {
    id
    projectQueryId
    name
    description
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectTemplatePreviewFragment = gql`
  fragment ProjectTemplatePreview on ProjectTemplateNode {
    id
    projectQueryId
    name
    description
    createdAt
    createdBy {
      firstName
      lastName
      profilePicture {
        imageThumbnail {
          url
        }
      }
    }
    updatedAt
    updatedBy {
      firstName
      lastName
      profilePicture {
        imageThumbnail {
          url
        }
      }
    }
  }
`;
