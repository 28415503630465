import { ViewPreset, ViewPresetConfig } from '@bryntum/schedulerpro';
import { getISOWeek, getQuarter } from 'date-fns';
import { Composer } from 'vue-i18n';

import { ScheduleConstants } from '@/common/bryntum/constants';

export const enum SchedulerViewPreset {
  YEAR_QUARTER = 'yearQuarter',
  QUARTER_MONTH = 'quarterMonth',
  QUARTER_MONTH_2 = 'quarterMonth2',
  MONTH_WEEK = 'monthWeek',
  MONTH_WEEK_2 = 'monthWeek2',
  MONTH_WEEK_3 = 'monthWeek3',
  MONTH_WEEK_4 = 'monthWeek4',
  MONTH_WEEK_DAY = 'monthWeekDay',
  MONTH_WEEK_DAY_2 = 'monthWeekDay2',
  MONTH_WEEK_DAY_3 = 'monthWeekDay3',
  DAY_HOUR = 'dayHour',
  DAY_HOUR_2 = 'dayHour2',
}

/**
 * Specifies if the planning of events in the scheduler is in day resolution for the given preset.
 * @param preset
 * @returns
 */
export function isDayResolution(preset: ViewPresetConfig | ViewPreset | undefined): boolean {
  return preset?.timeResolution?.unit === 'day';
}
/**
 * Specifies if the planning of events in the scheduler is in hour resolution for the given preset.
 * @param preset
 * @returns
 */
export function isMinuteResolution(preset: ViewPresetConfig | ViewPreset | undefined): boolean {
  return preset?.timeResolution?.unit === 'minute';
}
/**
 * Specifies if the lowest level of the given preset is in day granularity.
 * @param preset
 * @returns
 */
export function isDayGranularity(preset: ViewPresetConfig | ViewPreset | undefined): boolean {
  const headerLength = preset?.headers?.length;
  if (!headerLength) return false;
  return preset.headers?.[headerLength - 1]?.unit === 'day';
}
/**
 * Specifies if the lowest level of the given preset is in hour granularity.
 * @param preset
 * @returns
 */
export function isMinuteGranularity(preset: ViewPresetConfig | ViewPreset | undefined): boolean {
  const headerLength = preset?.headers?.length;
  if (!headerLength) return false;
  return preset.headers?.[headerLength - 1]?.unit === 'minute';
}

export function getDefaultViewPreset(): string {
  return SchedulerViewPreset.MONTH_WEEK_DAY;
}

/**
 * Renderers for the headers of the presets.
 */
const useHeaderRenderers = (i18n: Composer) => {
  const renderYear = () => (date: Date) => {
    return date.getFullYear();
  };
  const renderQuarter =
    ({ includeYear }: { includeYear?: boolean } = {}) =>
    (date: Date) => {
      const year = date.getFullYear();
      const shortYear = year.toString().slice(-2);
      return includeYear ? `Q${getQuarter(date)}/${shortYear}` : `Q${getQuarter(date)}`;
    };
  const renderMonth =
    ({ short, includeYear }: { short?: boolean; includeYear?: boolean } = {}) =>
    (date: Date) => {
      let format = '';
      if (short && includeYear) {
        format = 'monthShortYear';
      } else if (short && !includeYear) {
        format = 'monthShort';
      } else if (!short && includeYear) {
        format = 'monthLongYear';
      } else if (!short && !includeYear) {
        format = 'monthLong';
      }
      return i18n.d(new Date(date), format);
    };
  const renderWeek =
    ({ short }: { short?: boolean } = {}) =>
    (date: Date) => {
      return short ? getISOWeek(date) : i18n.t('Calendar.calendarWeek', getISOWeek(date));
    };
  const renderDay =
    ({ short }: { short?: boolean } = {}) =>
    (date: Date) => {
      return short
        ? i18n.d(new Date(date), 'dateDMYShortW')
        : `<div class="tw-ds-text-xs tw-text-grey-300">${i18n.d(
            new Date(date),
            'weekDay',
          )} </div> <div class="tw-ds-text-sm--medium">${date.getDate()}</div>`;
    };
  const renderHour =
    ({ short }: { short?: boolean } = {}) =>
    (date: Date) => {
      return short ? date.getHours().toString() : `${date.getHours()}:00`;
    };

  return {
    renderYear,
    renderQuarter,
    renderMonth,
    renderWeek,
    renderDay,
    renderHour,
  };
};

export const SchedulerViewPresetTickWidths: Record<SchedulerViewPreset, number> = {
  [SchedulerViewPreset.YEAR_QUARTER]: 100,
  [SchedulerViewPreset.QUARTER_MONTH]: 60,
  [SchedulerViewPreset.QUARTER_MONTH_2]: 110,
  [SchedulerViewPreset.MONTH_WEEK]: 30,
  [SchedulerViewPreset.MONTH_WEEK_2]: 60,
  [SchedulerViewPreset.MONTH_WEEK_3]: 100,
  [SchedulerViewPreset.MONTH_WEEK_4]: 150,
  [SchedulerViewPreset.MONTH_WEEK_DAY]: 40,
  [SchedulerViewPreset.MONTH_WEEK_DAY_2]: 60,
  [SchedulerViewPreset.MONTH_WEEK_DAY_3]: 120,
  [SchedulerViewPreset.DAY_HOUR]: 30,
  [SchedulerViewPreset.DAY_HOUR_2]: 70,
};

/**
 * Returns custom view presets for the different zoom levels of the main scheduler.
 * @returns
 */
export function useSchedulerViewPresets(
  i18n: Composer,
  options: { showHourlyPresets?: boolean; showCollisionHeader?: boolean } = {},
): ViewPresetConfig[] {
  const headerRenderers = useHeaderRenderers(i18n);

  const yearQuarter: ViewPresetConfig = {
    id: SchedulerViewPreset.YEAR_QUARTER,
    name: SchedulerViewPreset.YEAR_QUARTER,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.YEAR_QUARTER],
    tickHeight: 50,
    displayDateFormat: 'll',
    shiftUnit: 'year',
    shiftIncrement: 1,
    defaultSpan: 10,
    timeResolution: {
      unit: 'month',
      increment: 1,
    },
    headers: [
      {
        unit: 'year',
        renderer: headerRenderers.renderYear(),
      },
      {
        unit: 'quarter',
        renderer: headerRenderers.renderQuarter(),
      },
    ],
    mainHeaderLevel: 0,
    columnLinesFor: 1,
  };

  const quarterMonth: ViewPresetConfig = {
    id: SchedulerViewPreset.QUARTER_MONTH,
    name: SchedulerViewPreset.QUARTER_MONTH,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.QUARTER_MONTH],
    tickHeight: 110,
    displayDateFormat: 'll',
    shiftUnit: 'month',
    shiftIncrement: 3,
    defaultSpan: 12,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'quarter',
        renderer: headerRenderers.renderQuarter({ includeYear: true }),
      },
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ short: true }),
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const quarterMonth2: ViewPresetConfig = {
    id: SchedulerViewPreset.QUARTER_MONTH_2,
    name: SchedulerViewPreset.QUARTER_MONTH_2,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.QUARTER_MONTH_2],
    tickHeight: 110,
    displayDateFormat: 'll',
    shiftUnit: 'month',
    shiftIncrement: 3,
    defaultSpan: 12,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'quarter',
        renderer: headerRenderers.renderQuarter({ includeYear: true }),
      },
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ short: true }),
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const monthWeek: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK,
    name: SchedulerViewPreset.MONTH_WEEK,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK],
    tickHeight: 40,
    displayDateFormat: 'll',
    shiftUnit: 'week',
    shiftIncrement: 1,
    defaultSpan: 10,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ short: true, includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek({ short: true }),
        headerCellCls: 'calendar-week-row',
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const monthWeek2: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK_2,
    name: SchedulerViewPreset.MONTH_WEEK_2,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK_2],
    tickHeight: 40,
    displayDateFormat: 'll',
    shiftUnit: 'week',
    shiftIncrement: 1,
    defaultSpan: 10,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek({ short: true }),
        headerCellCls: 'calendar-week-row',
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const monthWeek3: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK_3,
    name: SchedulerViewPreset.MONTH_WEEK_3,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK_3],
    tickHeight: 105,
    displayDateFormat: 'll',
    shiftUnit: 'week',
    shiftIncrement: 5,
    defaultSpan: 6,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek(),
        headerCellCls: 'calendar-week-row',
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const monthWeek4: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK_4,
    name: SchedulerViewPreset.MONTH_WEEK_4,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK_4],
    tickHeight: 105,
    displayDateFormat: 'll',
    shiftUnit: 'week',
    shiftIncrement: 5,
    defaultSpan: 6,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek(),
        headerCellCls: 'calendar-week-row',
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const monthWeekDay: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK_DAY,
    name: SchedulerViewPreset.MONTH_WEEK_DAY,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK_DAY],
    tickHeight: 50,
    displayDateFormat: 'll',
    shiftUnit: 'week',
    shiftIncrement: 1,
    defaultSpan: 10,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek(),
        headerCellCls: 'calendar-week-row',
      },
      {
        unit: 'day',
        renderer: headerRenderers.renderDay(),
      },
    ],
    mainHeaderLevel: 2,
    columnLinesFor: 2,
  };

  const monthWeekDay2: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK_DAY_2,
    name: SchedulerViewPreset.MONTH_WEEK_DAY_2,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK_DAY_2],
    tickHeight: 80,
    displayDateFormat: 'll LST',
    shiftUnit: 'week',
    shiftIncrement: 1,
    defaultSpan: 1,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek(),
        headerCellCls: 'calendar-week-row',
      },
      {
        unit: 'day',
        renderer: headerRenderers.renderDay(),
      },
    ],
    mainHeaderLevel: 2,
    columnLinesFor: 2,
  };

  const monthWeekDay3: ViewPresetConfig = {
    id: SchedulerViewPreset.MONTH_WEEK_DAY_3,
    name: SchedulerViewPreset.MONTH_WEEK_DAY_3,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.MONTH_WEEK_DAY_3],
    tickHeight: 80,
    displayDateFormat: 'll LST',
    shiftUnit: 'week',
    shiftIncrement: 1,
    defaultSpan: 1,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'month',
        renderer: headerRenderers.renderMonth({ includeYear: true }),
      },
      {
        unit: 'week',
        renderer: headerRenderers.renderWeek(),
        headerCellCls: 'calendar-week-row',
      },
      {
        unit: 'day',
        renderer: headerRenderers.renderDay(),
      },
    ],
    mainHeaderLevel: 2,
    columnLinesFor: 2,
  };

  const dayHour: ViewPresetConfig = {
    id: SchedulerViewPreset.DAY_HOUR,
    name: SchedulerViewPreset.DAY_HOUR,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.DAY_HOUR],
    tickHeight: 40,
    displayDateFormat: 'll LST',
    shiftUnit: 'day',
    shiftIncrement: 1,
    defaultSpan: 24,
    timeResolution: {
      unit: 'minute',
      increment: ScheduleConstants.MINUTE_SNAP,
    },
    headers: [
      {
        unit: 'day',
        renderer: headerRenderers.renderDay({ short: true }),
      },
      {
        unit: 'hour',
        renderer: headerRenderers.renderHour({ short: true }),
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const dayHour2: ViewPresetConfig = {
    id: SchedulerViewPreset.DAY_HOUR_2,
    name: SchedulerViewPreset.DAY_HOUR_2,
    tickWidth: SchedulerViewPresetTickWidths[SchedulerViewPreset.DAY_HOUR_2],
    tickHeight: 40,
    displayDateFormat: 'll LST',
    shiftUnit: 'day',
    shiftIncrement: 1,
    defaultSpan: 24,
    timeResolution: {
      unit: 'minute',
      increment: ScheduleConstants.MINUTE_SNAP,
    },
    headers: [
      {
        unit: 'day',
        renderer: headerRenderers.renderDay({ short: true }),
      },
      {
        unit: 'hour',
        renderer: headerRenderers.renderHour(),
      },
    ],
    mainHeaderLevel: 1,
    columnLinesFor: 1,
  };

  const presets = [
    yearQuarter,
    quarterMonth,
    quarterMonth2,
    monthWeek,
    monthWeek2,
    monthWeek3,
    monthWeek4,
    monthWeekDay,
    monthWeekDay2,
    monthWeekDay3,
    dayHour,
    dayHour2,
  ].filter((preset) => {
    return options.showHourlyPresets || !isMinuteResolution(preset);
  });

  return presets.flatMap((preset) => {
    if (!options.showCollisionHeader) {
      return [preset];
    }

    /**
     * It is not possible to dynamically change the header configuration of a preset. Hence, in order to
     * place the colliison row within the header, we duplicate all presets with a "collision" version
     * that includes an additional header row for the collision row.
     */
    return [
      preset,
      {
        ...preset,
        id: getCollisionPresetId(preset.id?.toString() ?? ''),
        headers: [
          ...preset.headers!,
          {
            ...preset.headers![preset.headers!.length - 1],
            renderer: () => '',
            headerCellCls: 'collision-header',
          },
        ],
      },
    ];
  });
}

export function isCollisionPreset(presetId: string): boolean {
  return presetId.includes('collision');
}

export function getCollisionPresetId(presetId: string): string {
  return `${presetId}-collision`;
}
