import { Composer } from 'vue-i18n';

import { Collision, useCollisionStore } from '@/features/collisions';
import { LoggingService } from '@/interfaces/services';
import { h } from '@/jsx/jsxFactory';

import { CollisionSummaryNavigator } from './CollisionSummaryNavigator';
import JumpToTodayButton from './JumpToTodayButton';

export type ScheduleHeaderCornerCellProps = {
  i18n: Composer;
  collisionStore: ReturnType<typeof useCollisionStore>;
  collisionsBeforeRange: Collision[];
  collisionsAfterRange: Collision[];
  loggingService: LoggingService;
};

export const ScheduleHeaderCornerCellId = 'schedule-header-corner-cell';

export default function ScheduleHeaderCornerCell(props: ScheduleHeaderCornerCellProps) {
  const hasCollisions = props.collisionStore.collisions.size > 0;
  return (
    <div
      id={ScheduleHeaderCornerCellId}
      class="show-schedule-subheader tw-flex tw-flex-col tw-flex-grow"
    >
      <div class="tw-flex tw-items-center tw-justify-center tw-flex-1 tw-w-full">
        {JumpToTodayButton(props)}
      </div>
      {hasCollisions && CollisionSummaryNavigator(props)}
    </div>
  );
}
