import { computed, ComputedRef } from 'vue';

import bremerLogo from '@/assets/themes/bremer/logo.svg';
import defaultWithText from '@/assets/themes/default/logo.svg';
import defaultWithoutText from '@/assets/themes/default/logo_without_text.svg';
import dresslerLogo from '@/assets/themes/dressler/logo.svg';
import jwaLogo from '@/assets/themes/jwa/logo.svg';
import mbnWithText from '@/assets/themes/mbn/logo.svg';
import mbnWithoutText from '@/assets/themes/mbn/logo_without_text.svg';
import schmeingLogo from '@/assets/themes/schmeing/logo.svg';
import { useGlobalStore } from '@/common/globalStore';
import { WHITELABEL_TENANT_IDS } from '@/common/themes/const';
import { IS_MBN } from '@/utils/config';

import { TenantThemes, Theme } from './types';

const TENANT_THEMES: TenantThemes = {
  [WHITELABEL_TENANT_IDS.MBN]: {
    drawerColor: '#FFFFFF',
    drawerBackgroundColor: '#293134',
    drawerHighlightColor: '#FE8F1A',
    drawerHighlightBackgroundColor: '#3F4B4F',
    drawerDividerColor: 'rgba(41, 49, 52, 0.36)',
    drawerContrastColor: '#293134',
    drawerContrastBackgroundColor: '#FFFFFF',
    drawerContrastHighlightColor: '#FE8F1A',
    drawerContrastHighlightBackgroundColor: '#3F4B4F',
    icon: mbnWithText,
    iconWithoutText: mbnWithoutText,
  },
  [WHITELABEL_TENANT_IDS.JWA]: {
    drawerColor: '#FFFFFF',
    drawerBackgroundColor: '#383B37',
    drawerHighlightColor: '#383B37',
    drawerHighlightBackgroundColor: '#FFFFFF',
    drawerDividerColor: 'rgba(56, 59, 55, 0.36)',
    drawerContrastColor: '#383B37',
    drawerContrastBackgroundColor: '#FFFFFF',
    drawerContrastHighlightColor: '#FFFFFF',
    drawerContrastHighlightBackgroundColor: '#383B37',
    icon: jwaLogo,
    iconWithoutText: jwaLogo,
  },
  [WHITELABEL_TENANT_IDS.DRESSLER]: {
    drawerColor: '#383A3B',
    drawerBackgroundColor: '#DBEAF1',
    drawerHighlightColor: '#FFFFFF',
    drawerHighlightBackgroundColor: '#009FE3',
    drawerDividerColor: '#DBEAF1',
    drawerContrastColor: '#383A3B',
    drawerContrastBackgroundColor: '#FFFFFF',
    drawerContrastHighlightColor: '#FFFFFF',
    drawerContrastHighlightBackgroundColor: '#009FE3',
    icon: dresslerLogo,
    iconWithoutText: dresslerLogo,
  },
  [WHITELABEL_TENANT_IDS.SCHMEING]: {
    drawerColor: '#FFFFFF',
    drawerBackgroundColor: '#092657',
    drawerHighlightColor: '#092657',
    drawerHighlightBackgroundColor: '#FFD616',
    drawerDividerColor: 'rgba(9, 38, 87, 0.36)',
    drawerContrastColor: '#092657',
    drawerContrastBackgroundColor: '#FFFFFF',
    drawerContrastHighlightColor: '#092657',
    drawerContrastHighlightBackgroundColor: '#FFD616',
    icon: schmeingLogo,
    iconWithoutText: schmeingLogo,
  },
  [WHITELABEL_TENANT_IDS.BREMER]: {
    drawerColor: '#FFFFFF',
    drawerBackgroundColor: '#01011A',
    drawerHighlightColor: '#FFFFFF',
    drawerHighlightBackgroundColor: '#0067FE',
    drawerDividerColor: 'rgba(1, 1, 26, 0.36)',
    drawerContrastColor: '#01011A',
    drawerContrastBackgroundColor: '#FFFFFF',
    drawerContrastHighlightColor: '#FFFFFF',
    drawerContrastHighlightBackgroundColor: '#0067FE',
    icon: bremerLogo,
    iconWithoutText: bremerLogo,
  },
  DEFAULT: {
    drawerColor: '#FFFFFF',
    drawerBackgroundColor: '#002837',
    drawerHighlightColor: '#F2C36B',
    drawerHighlightBackgroundColor: '#004460',
    drawerDividerColor: '#ADCAD6',
    drawerContrastColor: '#005A7E',
    drawerContrastBackgroundColor: '#FFFFFF',
    drawerContrastHighlightColor: '#FFFFFF',
    drawerContrastHighlightBackgroundColor: '#F2C36B',
    icon: defaultWithText,
    iconWithoutText: defaultWithoutText,
  },
};

export function useTheme(): ComputedRef<Theme> {
  const globalStore = useGlobalStore();

  const theme = computed(() => {
    if (IS_MBN) {
      return TENANT_THEMES[WHITELABEL_TENANT_IDS.MBN];
    }

    const customTheme = TENANT_THEMES[globalStore.currentTenantId || ''];

    return customTheme || TENANT_THEMES.DEFAULT;
  });

  return theme;
}
