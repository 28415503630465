import { DependencyType, ReducedOrderScheduleState } from '@koppla-tech/scheduling-engine';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import {
  CustomFeatureAccess,
  FeatureDenyReason,
  useFeatureAccessStore,
} from '@/common/featureAccessStore';
import { CalendarEntity, MilestoneEntity, OrderDependencyEntity } from '@/common/types';
import { useCalendarStore } from '@/features/calendars';
import { useMilestoneStore } from '@/features/milestones';
import { MilestoneType } from '@/features/milestones/types';
import { useNotificationStore } from '@/features/notifications';
import { useOrderDependencyStore } from '@/features/orderDependencies';
import { useOrderStore } from '@/features/orders';
import { usePauseStore } from '@/features/pauses';
import { useWbsSectionStore } from '@/features/projectStructure';
import { useRTCController } from '@/features/realTimeCollaboration';
import { useGlobalMessageListener, useParentMessageListener } from '@/globalMessageListener';
import {
  getStatusReportOfStatus,
  mapDomainToSENGOrderStatus,
  mapStatusReportToOrderStatus,
} from '@/helpers/orders/status';
import { NodeName } from '@/repositories/utils/cache';
import { getBulkApiClient } from '@/services/bulkApiClient';
import { useLoggingService } from '@/services/logging/composable';
import { ProjectScheduleDto } from '@/services/store/bulkApiClient';
import { useProjectVersionStore } from '@/services/store/projectVersion/projectVersionStore';

import { versioningPreviewQueryParamName } from './config';

export const useProjectVersioningPreviewStore = defineStore(
  'project-versioning-preview-store',
  () => {
    const isInProjectVersioningPreview = ref(false);

    const currentVersionId = ref<string | null>();

    const loadingVersioningPreview = ref(false);

    const notificationStore = useNotificationStore();
    const orderStore = useOrderStore();
    const versionStore = useProjectVersionStore();
    const calendarStore = useCalendarStore();
    const sectionsStore = useWbsSectionStore();
    const milestoneStore = useMilestoneStore();
    const pauseStore = usePauseStore();
    const listener = useGlobalMessageListener();

    const rtcController = useRTCController();

    const dependencyStore = useOrderDependencyStore();
    const bulkClient = getBulkApiClient();

    const router = useRouter();
    const route = useRoute();

    const i18n = useI18n();

    useParentMessageListener((message) => {
      if (message.type === 'DISPLAY_VERSION') {
        openPreview({ id: message.data.versionId, projectId: message.data.projectId });
      }
    });

    const initialize = async (projectId: string) => {
      try {
        const currentQueryVersionId = route.query[versioningPreviewQueryParamName] as
          | string
          | undefined;
        if (typeof currentQueryVersionId !== 'string') return;
        const version = await versionStore.fetchOne(currentQueryVersionId);
        if (!version) {
          throw new Error("Version doesn't exist.");
        }
        if (version.project.id !== projectId) {
          throw new Error('Version belongs to another project.');
        }

        await openPreview({ id: version.id, projectId });
      } catch (e) {
        handleLoadingError(e);
      }
    };

    const openPreview = async (version: { id: string; projectId: string }) => {
      const versionId = version.id;
      currentVersionId.value = versionId;
      try {
        loadingVersioningPreview.value = true;

        const schedule = await bulkClient.getProjectSchedule({
          versionId,
          projectId: version.projectId,
        });
        if (currentVersionId.value !== versionId) return;

        if (!schedule) {
          throw new Error(`Unable to find schedule data for ${version}.`);
        }

        router.replace({ query: { [versioningPreviewQueryParamName]: version.id } });

        rtcController.startIgnoringEvents();

        isInProjectVersioningPreview.value = true;
        setScheduleStateOnStores(schedule);
        useFeatureAccessStore().setCustomAccess(getVersioningPreviewFeatureAccess());
        listener.onVersionLoad();
      } catch (e) {
        handleLoadingError(e);
      } finally {
        if (currentVersionId.value !== versionId) return;
        loadingVersioningPreview.value = false;
      }
    };

    const setScheduleStateOnStores = (schedule: ProjectScheduleDto) => {
      orderStore.setState(
        new Map(
          schedule.orders.map((order) => {
            return [
              order.id,
              {
                __typename: NodeName.ORDER,
                ...order,
                startAt: new SchedulingDate(order.startAt),
                finishAt: new SchedulingDate(order.finishAt),
                finishedAt:
                  order.finishedAt === null
                    ? order.finishedAt
                    : new SchedulingDate(order.finishedAt),
                status: getStatusReportOfStatus(order.status),
                progress: order.progress ?? 0,
                wbsSection: {
                  ...order.wbsSection!,
                  __typename: NodeName.SECTION,
                },
                calendar: { ...order.calendar, __typename: NodeName.CALENDAR },
                tenantTradeVariation: order.tenantTradeVariation!,
                contributorGroup: order.contributorGroup ? { id: order.contributorGroup.id } : null,
                tradeSequenceActivity: order.tradeSequenceActivity
                  ? {
                      ...order.tradeSequenceActivity,
                      __typename: NodeName.TRADE_SEQUENCE_ACTIVITY,
                    }
                  : null,
              },
            ];
          }),
        ),
      );

      sectionsStore.setState(
        new Map(
          schedule.wbsSections.map((s) => {
            return [
              s.id,
              {
                ...s,
                parentId: s.parent?.id ?? null,
              },
            ];
          }),
        ),
      );

      const milestones: MilestoneEntity[] = schedule.milestones.map((milestone) => {
        return {
          ...milestone,
          date: new SchedulingDate(milestone.date),
          acceptanceCriteria: [],
          isFixed: milestone.isFixed,
          type: milestone.isFixed ? MilestoneType.FIXED : MilestoneType.FLEXIBLE,
          completedAt: milestone.completedAt ? new Date(milestone.completedAt) : null,
        };
      });

      milestoneStore.setState(new Map(milestones.map((m) => [m.id, m])));

      pauseStore.setState(
        new Map(
          schedule.pauses.map((pause) => {
            return [
              pause.id,
              {
                ...pause,
                start: new SchedulingDate(pause.start),
                end: new SchedulingDate(pause.end),
              },
            ];
          }),
        ),
      );

      dependencyStore.setState(
        new Map(
          schedule.dependencies.map((dependency) => {
            const depEntity: OrderDependencyEntity = {
              ...dependency,
              type: dependency.type as DependencyType,
              from: dependency.from.milestoneId
                ? { __typename: 'ProjectMilestoneNode', id: dependency.from.milestoneId }
                : { __typename: 'OrderNode', id: dependency.from.orderId! },
              to: dependency.to.milestoneId
                ? { __typename: 'ProjectMilestoneNode', id: dependency.to.milestoneId }
                : { __typename: 'OrderNode', id: dependency.to.orderId! },
            };

            return [dependency.id, depEntity];
          }),
        ),
      );

      calendarStore.setState(
        new Map(
          schedule.calendars.map((calendar) => {
            const calendarEntity: CalendarEntity = {
              ...calendar,
              exceptions: calendar.exceptions.map((exception) => {
                return {
                  ...exception,
                  startAt: new SchedulingDate(exception.startAt),
                  finishAt: new SchedulingDate(exception.finishAt),
                };
              }),
            };

            return [calendar.id, calendarEntity];
          }),
        ),
      );

      const rtcController = useRTCController();
      const engineState: ReducedOrderScheduleState = {
        /**
         * Not relevant for read only use case
         */
        contributorGroups: [],
        calendars: calendarStore.calendarList,
        pauses: [...pauseStore.pauses.values()],
        milestones: milestoneStore.milestoneList.map((milestone) => {
          return { ...milestone, isFixed: milestone.type === MilestoneType.FIXED };
        }),
        dependencies: [...dependencyStore.dependencies.values()],
        orders: [...orderStore.orders.values()].map((order) => {
          return {
            ...order,
            status: mapDomainToSENGOrderStatus(mapStatusReportToOrderStatus(order.status)),
          };
        }),
      };
      /**
       * Vue components use computedWorkingTime of engine for
       * display of entity duration. We therefore need to update
       * the engine as well.
       */
      rtcController.setEngineState(engineState);
    };

    const handleLoadingError = (error: unknown) => {
      useLoggingService().error(error as Error, { code: 'LOADING_VERSION_PREVIEW_FAILED' });
      notificationStore.showAttentionNotification({
        titleI18nKey: i18n.t('versioning.versionPreviewLoadingErrorTitle'),
        bodyI18nKey: i18n.t('versioning.versionPreviewLoadingErrorMessage'),
      });
    };

    return {
      isInProjectVersioningPreview,
      loadingVersioningPreview,
      openPreview,
      initialize,
    };
  },
);

export function getVersioningPreviewFeatureAccess(): CustomFeatureAccess {
  return {
    id: 'versioning-preview',
    reason: FeatureDenyReason.VERSIONING_PREVIEW,
    uiState: {
      showLiveAvatars: false,
      showUndoRedo: false,
      enableElementSidebars: false,
      showCalendars: false,
      showSectionActions: false,
      showCollisionNotifications: false,
      allowPermanentFilterChanges: false,
      enableExports: {
        pdf: true,
        gantt: false,
        xlsx: false,
      },
    },
    features: {
      BASE_PLAN: {
        write: ref(false),
        read: ref(true),
      },
      SCHEDULE_PLANNING: {
        write: ref(false),
      },
      ORDER_PLANNING: {
        write: ref(false),
      },
      TRADE_SEQUENCES: {
        write: ref(false),
        read: ref(false),
      },
      DEPENDENCY_PLANNING: {
        write: ref(false),
      },
      MILESTONE_REPORTING: {
        write: ref(false),
      },
      ORDER_DOCUMENTS: {
        write: ref(false),
      },
      PLANNING_MODE: {
        write: ref(false),
        read: ref(false),
      },
      PROGRESS_REPORTING: {
        write: ref(false),
      },
      TICKETS: {
        write: ref(false),
        read: ref(false),
      },
      TICKET_REPORTING: {
        write: ref(false),
        read: ref(false),
      },
      VERSIONING: {
        write: ref(false),
        read: ref(false),
      },
    },
  };
}
