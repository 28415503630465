import { compareDesc, parseISO } from 'date-fns';
import { OperationNames } from 'events.schema';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { generateRTCMessageId, useRTCController } from '@/features/realTimeCollaboration';
import { gql as gqlFn } from '@/graphql/__generated__';
import {
  CreateProjectVersionMutation,
  CreateProjectVersionMutationVariables,
  ProjectVersionsQuery,
} from '@/graphql/__generated__/graphql';
import ProjectVersionsAllQuery from '@/graphql/version/All.gql';
import ProjectVersionCreate from '@/graphql/version/Create.gql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import {
  QueryDataMerge,
  useCursorBasedPaginationWithoutCache,
} from '@/repositories/utils/pagination';
import {
  PROJECT_VERSIONS_BATCH_SIZE,
  PROJECT_VERSIONS_POLLING_INTERVAL,
  PROJECT_VERSIONS_REFETCH_BATCH_SIZE,
  ProjectVersion,
  ProjectVersionsPaginationQueryVariables,
} from '@/services/store/projectVersion/projectVersionTypes';

export const useProjectVersionStore = defineStore('project-version-store', () => {
  /** Backend communication and pagination */
  let fetchNext: () => Promise<void>;
  let refetchData: (refetchLimit: number, compareResult: boolean) => Promise<ProjectVersion[]>;
  let currentProjectId: string;
  let usePolling: boolean;
  const queryLoading = ref(false);
  const mutationLoading = ref(false);
  const error = ref(undefined as Error | undefined);
  const hasNextPage = ref(false);

  /** Version state */
  const rawVersions = ref([] as ProjectVersion[]);
  const sortedVersions = computed(() =>
    rawVersions?.value?.length
      ? [...rawVersions.value].sort((firstVersion, secondVersion) =>
          compareDesc(firstVersion.timestamp, secondVersion.timestamp),
        )
      : [],
  );

  async function initialize(projectId: string): Promise<void> {
    if (currentProjectId && currentProjectId === projectId) {
      return refetch().then(() => togglePolling(true));
    }
    currentProjectId = projectId;

    rawVersions.value = [];
    error.value = undefined;
    usePolling = false;

    const dataMerger: QueryDataMerge<ProjectVersion> = ({ existing, newData }) => {
      // Refetch can include already available ids so we want to exclude them
      const existingIds = new Set(existing.map((e) => e.id));
      return existing.concat(newData.filter((n) => !existingIds.has(n.id)));
    };

    // Retrieve versions from backend paginated
    const paginatedProjectVersions = useCursorBasedPaginationWithoutCache<
      ProjectVersionsQuery,
      ProjectVersionsPaginationQueryVariables,
      NonNullable<ProjectVersionsQuery['projectVersions']>,
      ProjectVersion
    >(
      ProjectVersionsAllQuery,
      { project: currentProjectId, limit: PROJECT_VERSIONS_BATCH_SIZE },
      'projectVersions',
      { data: rawVersions, loading: queryLoading, error, hasNextPage },
      (versions) => {
        return flattenNodeConnection(versions).map((version) => ({
          ...version,
          timestamp: parseISO((version.timestamp as Date).toString()),
        }));
      },
      {
        mergeData: dataMerger,
        fetchNew: {
          merge: dataMerger,
          varBuilder(variables) {
            return { ...variables, limit: PROJECT_VERSIONS_REFETCH_BATCH_SIZE, after: undefined };
          },
        },
      },
    );

    fetchNext = paginatedProjectVersions.fetchNext;
    refetchData = async () => {
      // Versions are newer deleted so there can only be new versions.
      // Therefore we just query for new versions instead of doing actual refresh.
      await paginatedProjectVersions.fetchNew();
      return rawVersions.value;
    };
    togglePolling(true);

    return Promise.resolve();
  }

  function fetchAll(projectId: string): {
    versions: Ref<ProjectVersion[]>;
    loading: Ref<boolean>;
  } {
    const client = useApolloClient();
    const versions = ref<ProjectVersion[]>([]);
    const loading = ref(true);

    client
      .query<ProjectVersionsQuery, ProjectVersionsPaginationQueryVariables>({
        query: ProjectVersionsAllQuery,
        variables: { project: projectId, limit: 10000 },
      })
      .then((result) => {
        versions.value = flattenNodeConnection(result.data.projectVersions).map((version) => ({
          ...version,
          timestamp: new Date(version.timestamp),
        }));
      })
      .finally(() => {
        loading.value = false;
      });

    return {
      versions,
      loading,
    };
  }

  function fetchOne(versionId: string) {
    const client = useApolloClient();

    const query = gqlFn(/* GraphQL */ `
      query ProjectVersion($version: ID!) {
        projectVersion(version: $version) {
          id
          project {
            id
          }
        }
      }
    `);

    return client
      .query({
        query,
        variables: {
          version: versionId,
        },
      })
      .then((result) => {
        return result.data.projectVersion;
      });
  }

  function createVersion(name: string): Promise<void | ProjectVersion[]> {
    const client = useApolloClient();
    if (!name) return Promise.resolve();
    mutationLoading.value = true;
    return client
      .mutate<CreateProjectVersionMutation, CreateProjectVersionMutationVariables>({
        mutation: ProjectVersionCreate,
        variables: {
          project: currentProjectId,
          name,
        },
      })
      .then(() => {
        return refetch();
      })
      .catch((mutationError) => {
        error.value = mutationError;
      })
      .finally(() => {
        mutationLoading.value = false;
      });
  }

  function restoreVersion(versionId: string, name: string) {
    const rtcController = useRTCController();

    return rtcController.pushLocalProjectChangeEvent({
      messageId: generateRTCMessageId(),
      operation: {
        name: OperationNames.RestoreProjectVersion,
        input: { versionId, newVersionName: name },
      },
    });
  }

  function togglePolling(poll: boolean): void {
    usePolling = poll;
    function refresh() {
      if (!usePolling) {
        return;
      }
      refetch(true).finally(() => {
        setTimeout(refresh, PROJECT_VERSIONS_POLLING_INTERVAL);
      });
    }
    setTimeout(refresh, PROJECT_VERSIONS_POLLING_INTERVAL);
  }

  async function fetchMore(): Promise<void> {
    if (!fetchNext) return Promise.resolve();
    return fetchNext();
  }

  function refetch(compareResult = false): Promise<ProjectVersion[]> {
    if (!refetchData) return Promise.resolve([]);
    return refetchData(PROJECT_VERSIONS_REFETCH_BATCH_SIZE, compareResult);
  }

  return {
    queryLoading,
    mutationLoading,
    error,
    hasNextPage,
    sortedVersions,
    initialize,
    fetchAll,
    togglePolling,
    fetchMore,
    fetchOne,
    createVersion,
    restoreVersion,
  };
});
