import { clearSchedulerReference, getScheduler } from '@/services/store/integrations/scheduler';
import { ScheduleStore, Sidebars } from '@/services/store/schedule';
import { useSchedulePopupStore } from '@/services/store/schedulePopup';

import { getInitialEntityState } from '../store';

export function destroy(store: ScheduleStore): void {
  store.utils.lastProjectId = store.projectId;
  store.utils.hasEventSelection = false;
  store.utils.multiSelectedEvents = new Map();
  store.utils.unwatchers.forEach((unwatch) => unwatch());
  store.utils.unwatchers = [];
  store.sidebar = null;
  store.currentSidebar = Sidebars.CLOSED;
  store.clearClipboard();
  useSchedulePopupStore().reset();

  // clear all entities from schedule store to prevent state inconsistenties, as
  // entity stores should be responsible for data synchronization
  store.entities = getInitialEntityState();

  removeListenersAndDestroyScheduler();
  store.initialized = false;
}

function removeListenersAndDestroyScheduler(): void {
  const scheduler = getScheduler();
  if (!scheduler) return;

  scheduler.removeAllListeners();
  scheduler.destroy();
  clearSchedulerReference();
}
