<template>
  <main class="the-main" :class="`--${props.mode}`">
    <ProjectPlanningModeBanner v-if="isInPlanningMode" />
    <ProjectTemplateModeBanner v-else-if="isInProjectTemplateMode" />
    <div class="the-main-content">
      <slot />
    </div>
  </main>
</template>

<script setup lang="ts">
import ProjectPlanningModeBanner from '@/features/projectAlternatives/components/ProjectPlanningModeBanner.vue';
import ProjectTemplateModeBanner from '@/features/projectTemplates/components/ProjectTemplateModeBanner.vue';

const props = defineProps({
  mode: {
    type: String as PropType<'no-drawer' | 'planning' | 'project-template' | 'default'>,
    default: 'default',
  },
});

const isInPlanningMode = computed(() => props.mode === 'planning');
const isInProjectTemplateMode = computed(() => props.mode === 'project-template');
</script>

<style scoped>
.the-main {
  @apply tw-flex tw-flex-col tw-grow tw-overflow-y-auto tw-max-w-full tw-h-screen tw-p-0;
}

.the-main.--default {
  @apply tw-pl-[var(--kop-drawer-width)];
}

.the-main-content {
  @apply tw-flex tw-flex-col tw-flex-grow tw-max-h-screen;
}

.the-main.--planning .the-main-content {
  @apply tw-border-attention-500 tw-border-solid tw-border-8 tw-border-t-0;
}
.the-main.--project-template .the-main-content {
  @apply tw-border-petrol-400 tw-border-solid tw-border-8 tw-border-t-0;
}
</style>
