import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

import {
  fetchProjectTemplateQuery,
  fetchProjectTemplatesQuery,
} from '@/features/projectTemplates/projectTemplatesGql';
import { TemplateMode } from '@/features/projectTemplates/types';
import {
  ProjectTemplateFragment,
  ProjectTemplatePreviewFragment,
  ProjectTemplateQueryVariables,
  ProjectTemplatesQueryVariables,
} from '@/graphql/__generated__/graphql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { useRouteNames } from '@/router/helpers/routeNames';

export const useProjectTemplatesStore = defineStore('project-templates-store', () => {
  const route = useRoute();

  const { Routes, checkRoutesContain } = useRouteNames();

  const isInitialized = ref(false);
  const loading = ref(false);
  const templates = ref<Map<string, ProjectTemplatePreviewFragment>>(new Map());
  const isInProjectTemplateMode = ref(false);
  const mode = ref<TemplateMode>('preview');

  watchEffect(() => {
    isInProjectTemplateMode.value = checkRoutesContain(Routes.ProjectTemplate, route.name);
    if (isInProjectTemplateMode.value) {
      mode.value = route.query.mode === 'edit' ? 'edit' : 'preview';
    }
  });

  const currentProjectTemplate = ref<ProjectTemplateFragment | null>(null);
  function fetchCurrentProjectTemplate(vars: ProjectTemplateQueryVariables) {
    const client = useApolloClient();

    return client
      .query({
        query: fetchProjectTemplateQuery,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const { projectTemplate } = result.data;

        currentProjectTemplate.value = projectTemplate ?? null;

        return projectTemplate;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function fetchProjectTemplates(vars: ProjectTemplatesQueryVariables) {
    loading.value = true;

    const client = useApolloClient();

    return client
      .query({
        query: fetchProjectTemplatesQuery,
        variables: vars,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const projectTemplates = flattenNodeConnection(result.data.projectTemplates);

        templates.value = new Map(projectTemplates.map((template) => [template.id, template]));

        return projectTemplates;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  const initialize = (tenantId: string) => {
    return fetchProjectTemplates({ tenant: tenantId }).finally(() => {
      isInitialized.value = true;
    });
  };

  return {
    currentProjectTemplate,
    isInitialized,
    isInProjectTemplateMode,
    loading,
    mode,
    templates,
    fetchCurrentProjectTemplate,
    fetchProjectTemplates,
    initialize,
  };
});
