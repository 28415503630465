import { SchedulerEventModel } from '@bryntum/schedulerpro';
import { Composer } from 'vue-i18n';
import { Router } from 'vue-router';

import { useCollisionStore } from '@/features/collisions';
import { useProjectStore } from '@/features/projects';
import {
  getCollisionPresetId,
  getDefaultViewPreset,
  isCollisionPreset,
  useSchedulerViewPresets,
} from '@/features/schedule/bryntum/presets';
import { OwnTenantFragment } from '@/graphql/__generated__/graphql';
import { Routes } from '@/router/helpers/routeNames';
import { getScheduler, toggleCssClassForSchedule } from '@/services/store/integrations/scheduler';
import { ScheduleStore } from '@/services/store/schedule';
import { updateLocale } from '@/services/store/schedule/utils/updateLocale';
import { useScheduleAppearanceStore } from '@/services/store/scheduleAppearance/store';
import { useTenantStore } from '@/services/store/tenant';
import { useUserStore } from '@/services/store/user';
import { getBrowserLanguage, Language } from '@/utils/languages';

import {
  getCurrentViewPreset,
  refreshScheduleUI,
  registerViewPresets,
  scrollToEvent,
  zoomToViewPreset,
} from '../../../../../features/schedule/bryntum/schedulerInteractions';

export function setScheduleLocale(i18n: Composer) {
  const userStore = useUserStore();
  const scheduleAppearanceStore = useScheduleAppearanceStore();

  scheduleAppearanceStore.locale =
    (userStore.ownUser?.languageCode as Language) ?? getBrowserLanguage();

  const scheduler = getScheduler();
  if (!scheduler) {
    return;
  }

  /**
   * Bryntum initially always set the locale to the current browser language. There is no way to specify the initial locale.
   * If you try to set the locale, while bryntum is not ready, it will not work and still use the browser language.
   * Therefore we need to wait until the project is ready and even then it only works with a timeout.
   * The timeout amount is arbitrary, I wanted to use something that is not too long, but (hopefully) still long enough to work.
   */
  scheduler.whenProjectReady(() => {
    setTimeout(() => {
      updateLocale(i18n, scheduleAppearanceStore.locale);
    }, 500);
  });
}

export async function initializeTenant(tenantId: string): Promise<OwnTenantFragment | null> {
  const tenantStore = useTenantStore();
  return tenantStore.fetchOwn({ id: tenantId });
}

export function updateViewPresets(i18n: Composer, isHourlyProject: boolean): void {
  const scheduleAppearanceStore = useScheduleAppearanceStore();
  const collisionStore = useCollisionStore();
  const scheduler = getScheduler();

  const presets = useSchedulerViewPresets(i18n, {
    showHourlyPresets: isHourlyProject,
    showCollisionHeader: collisionStore.collisions.size > 0,
  });
  registerViewPresets(scheduler, presets);

  scheduleAppearanceStore.zoomPresets = presets
    .map((preset) => preset.id as string)
    .filter((presetId) => !isCollisionPreset(presetId));

  toggleCssClassForSchedule('show-collision-header', collisionStore.collisions.size > 0);

  setViewPreset(scheduleAppearanceStore.currentZoomPreset);
}

export function setViewPreset(presetId: string): string {
  const schedulerAppearanceStore = useScheduleAppearanceStore();
  const collisionStore = useCollisionStore();

  let newPresetId = presetId;
  const newPresetDoesNotExist = !schedulerAppearanceStore.zoomPresets.find(
    (presetId) => newPresetId === presetId,
  );
  if (newPresetDoesNotExist) {
    newPresetId = getDefaultViewPreset();
  }

  const currentAppliedPreset = getCurrentViewPreset(getScheduler());
  const currentAppliedPresetId = currentAppliedPreset?.id.toString() ?? '';
  // applied preset is the collision preset if there are collisions, but we never persist this in the appearance store
  const newAppliedPresetId =
    collisionStore.collisions.size > 0 ? getCollisionPresetId(newPresetId) : newPresetId;

  // if correct preset is already applied or no preset applied yet, just update the appearance store to keep in sync, but manual zoom is not necessary
  if (!currentAppliedPresetId || currentAppliedPresetId === newAppliedPresetId) {
    schedulerAppearanceStore.currentZoomPreset = newPresetId;
    return newPresetId;
  }

  zoomToViewPreset(getScheduler(), newAppliedPresetId);
  schedulerAppearanceStore.currentZoomPreset = newPresetId;
  return newPresetId;
}

export function watchProjectAddress(): void {
  const projectStore = useProjectStore();
  const currentProject = toRef(projectStore, 'currentProject');

  watch(
    () => currentProject.value?.address,
    () => {
      const scheduler = getScheduler();
      if (!scheduler) return;

      // Update main row on address change
      refreshScheduleUI(scheduler);
    },
  );
}

export function openObject(store: ScheduleStore, objectId: string, router?: Router): void {
  const scheduler = getScheduler();
  if (!objectId || !scheduler) return;

  scheduler.whenProjectReady(() => {
    const event = store.entities.events.get(objectId);
    const dependency = store.entities.dependencies.get(objectId);

    if (!event && !dependency && router) {
      const query = { ...router.currentRoute.value.query };
      const params = { ...router.currentRoute.value.params };
      delete params.objectId;
      router.replace({ name: Routes.Lean.Schedule, params, query }).catch(() => {});
    }

    store.openSidebar({ event, dependency });
    if (event) {
      scheduler.selectEvent(scheduler.eventStore.getById(objectId) as SchedulerEventModel);
      scrollToEvent(scheduler, objectId);
    }
    if (dependency) {
      scrollToEvent(scheduler, dependency.from);
    }
  });
}
