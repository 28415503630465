import { SchedulerViewPreset } from '../schedule/bryntum/presets';

export const MIN_TICK_WIDTH = 52; // roughly length of "CW50", multiple of 4
// digital export supports infinite content, so we can be more generous with the space taken
export const MIN_TICK_WIDTH_DIGITAL = MIN_TICK_WIDTH * 2;
export const PPI = 96; // pixels per inch on a 20" 1920px monitor
export const DEFAULT_PRINT_MARGIN = 0.35 * PPI;
export const EXPORT_RESOURCE_MARGIN = 2;
export const EXPORT_BAR_MARGIN = EXPORT_RESOURCE_MARGIN;
export const EXPORT_TIMEOUT_MS = 20000;
export const CSS_EXPORT_WRAPPER = 'kop-pdf-export-wrapper';
export const EXPORT_CSS_EVENT_LINE_HEIGHT_VARIABLE = '--export-order-event-line-height';

/**
 * The height of the header in pixels for each view preset to subtract from complete height.
 * We cannot calculate this dynamically because the header is not rendered in the PDF at this point.
 */
export const HARDCODED_HEADER_HEIGHT: Record<SchedulerViewPreset, number> = {
  [SchedulerViewPreset.YEAR_QUARTER]: 75.5,
  [SchedulerViewPreset.QUARTER_MONTH]: 75.5,
  [SchedulerViewPreset.QUARTER_MONTH_2]: 75.5,
  [SchedulerViewPreset.MONTH_WEEK]: 75.5,
  [SchedulerViewPreset.MONTH_WEEK_2]: 75.5,
  [SchedulerViewPreset.MONTH_WEEK_3]: 75.5,
  [SchedulerViewPreset.MONTH_WEEK_4]: 75.5,
  [SchedulerViewPreset.MONTH_WEEK_DAY]: 105.5,
  [SchedulerViewPreset.MONTH_WEEK_DAY_2]: 105.5,
  [SchedulerViewPreset.MONTH_WEEK_DAY_3]: 105.5,
  [SchedulerViewPreset.DAY_HOUR]: 75.5,
  [SchedulerViewPreset.DAY_HOUR_2]: 75.5,
};
