import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';

import { useRouteNames } from './routeNames';

const { Routes } = useRouteNames();

export function getLoginRoute(fullPath?: string): RouteLocationRaw {
  if (!fullPath) return { name: Routes.Unauthenticated.Login };
  const encodedTo = encodeURI(fullPath);

  return { name: Routes.Unauthenticated.Login, query: { login_redirect: encodedTo } };
}

export function getNoMembershipsRoute(): RouteLocationRaw {
  return {
    name: Routes.User.NoMemberships,
  };
}

export function getProjectsAllOrPickTenantRoute(tenantId?: string) {
  if (tenantId) {
    return { name: Routes.Tenant.AllProjects, params: { tenantId } };
  }
  return { name: Routes.Base.PickTenant };
}

export function getPickTenantRoute(): RouteLocationRaw {
  return { name: Routes.Base.PickTenant };
}

export function getRouteForMembershipStatus(
  to: RouteLocationNormalized,
  membership: MembershipWithTenant | null | undefined,
  memberships: MembershipWithTenant[],
): boolean | RouteLocationRaw {
  if (membership) return false;

  if (memberships.length) {
    return getPickTenantRoute();
  }
  return to.name !== Routes.User.NoMemberships ? getNoMembershipsRoute() : true;
}
