import { defineStore, storeToRefs } from 'pinia';

import { useIsOnline } from '@/common/composables/useIsOnline';
import { useGlobalStore } from '@/common/globalStore';
import { useMembershipStore } from '@/features/memberships/membershipStore';
import { useOrderStore } from '@/features/orders';
import { useProjectContributorStore } from '@/features/projectContributors/store/projectContributorStore';
import { useProjectStore } from '@/features/projects/projectStore';
import { LifecycleStatus } from '@/features/projects/projectUtils';
import { useRTCClient } from '@/features/realTimeCollaboration';
import { TicketStatus } from '@/features/tickets';
import { ProjectPermission } from '@/graphql/__generated__/graphql';
import { orderIsDoneOrReportedDone, StatusReport } from '@/helpers/orders/status';
import { TenantType } from '@/interfaces/repositories/tenant';
import { useTenantStore } from '@/services/store/tenant';

import { OrderEntity } from './types';

export enum Feature {
  BASE_PLAN = 'BASE_PLAN',
  DASH = 'DASH',
  MILESTONE_REPORTING = 'MILESTONE_REPORTING',
  ORDER_DETAILS = 'ORDER_DETAILS',
  ORDER_DOCUMENTS = 'ORDER_DOCUMENTS',
  ORDER_PLANNING = 'ORDER_PLANNING',
  DEPENDENCY_PLANNING = 'DEPENDENCY_PLANNING',
  PHOTO_DOCUMENTATION = 'PHOTO_DOCUMENTATION',
  PLANNING_MODE = 'PLANNING_MODE',
  PROGRESS_REPORTING = 'PROGRESS_REPORTING',
  PROJECT_SETTINGS = 'PROJECT_SETTINGS',
  PROJECT_CONTRIBUTORS = 'PROJECT_CONTRIBUTORS',
  PROJECT_CONTRIBUTOR_MEMBERS = 'PROJECT_CONTRIBUTOR_MEMBERS',
  DELETE_PROJECT_CONTRIBUTOR_MEMBERS = 'DELETE_PROJECT_CONTRIBUTOR_MEMBERS',
  SCHEDULE_EXPORT = 'SCHEDULE_EXPORT',
  SCHEDULE_PLANNING = 'SCHEDULE_PLANNING',
  TENANT = 'TENANT',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
  TENANT_PROJECTS = 'TENANT_PROJECTS',
  TENANT_SETTINGS = 'TENANT_SETTINGS',
  TENANT_TRADE_SEQUENCE_TEMPLATES = 'TENANT_TRADE_SEQUENCE_TEMPLATES',
  TENANT_TRADE_TEMPLATES = 'TENANT_TRADE_TEMPLATES',
  TENANT_PROJECT_STRUCTURE_TEMPLATES = 'TENANT_PROJECT_STRUCTURE_TEMPLATES',
  TENANT_PROJECT_TEMPLATES = 'TENANT_PROJECT_TEMPLATES',
  TICKETS = 'TICKETS',
  TICKET_REPORTING = 'TICKET_REPORTING',
  TRADE_SEQUENCES = 'TRADE_SEQUENCES',
  VERSIONING = 'VERSIONING',
}

export enum FeatureDenyReason {
  OFFLINE = 'OFFLINE',
  SERVER_UNREACHABLE = 'SERVER_UNREACHABLE',
  PERMISSION = 'PERMISSION',
  LICENSE = 'LICENSE',
  PLANNING_MODE = 'PLANNING_MODE',
  VERSIONING_PREVIEW = 'VERSIONING_PREVIEW',
  HOURLY_MODE = 'HOURLY_MODE',
  OBJECT_IMMUTABLE = 'OBJECT_IMMUTABLE',
  PROJECT_READONLY = 'PROJECT_READONLY',
}

export interface FeatureAccess {
  write: boolean;
  read: boolean;
  writeDenyReason: FeatureDenyReason | null;
  readDenyReason: FeatureDenyReason | null;
}

export type IndividualFeatureConditions = {
  isGeneralContractor: Ref<boolean>;
  isCurrentTenantMember: Ref<boolean>;
  isCurrentTenantAdmin: Ref<boolean>;
  isProjectTenantMember: Ref<boolean>;
  isProjectTenantAdmin: Ref<boolean>;
  isExternalProjectMember: Ref<boolean>;
  isCurrentProjectReadonly: Ref<boolean>;
  isProjectEditor: Ref<boolean>;
  isProjectReporter: Ref<boolean>;
  isProjectViewer: Ref<boolean>;
  isPartOfContributorGroupWithWritePermisson: Ref<boolean>;
  isConnectedToSocket: Ref<boolean>;
  isDailyProject: Ref<boolean>;
  isOnline: Ref<boolean>;
  isGroupMember: Ref<boolean>;
  tenantHasFeatureLicense: (feature: Feature) => Ref<boolean>;
  isResponsibleForOrder: (orderId?: string) => Ref<boolean>;
  isOrderDoneOrReportedDone: (orderId?: string) => Ref<boolean>;
  isResponsibleForContributorGroup(contributorGroupId?: string): Ref<boolean>;
  isContributorGroupMember(contributorGroupId?: string): Ref<boolean>;
  isAllowedToEditDependency(toOrderId?: string): Ref<boolean>;
  isAllowedToEditOrder(orderId?: string): Ref<boolean>;
};

export type FeatureAccessCallbackResponse = {
  write?: Ref<boolean>;
  read?: Ref<boolean>;
};

export type CustomFeatureAccess = {
  reason: FeatureDenyReason;
  id: string;
  features: Partial<Record<Feature, FeatureAccessCallbackResponse>>;
  uiState?: Partial<ConfigurableUIState>;
};

export type FeatureConditionsLoading = {
  loading: Ref<boolean>;
};

export type ConfigurableUIState = {
  showUndoRedo: boolean;
  showLiveAvatars: boolean;
  enableElementSidebars: boolean;
  showCalendars: boolean;
  showSectionActions: boolean;
  showCollisionNotifications: boolean;
  allowPermanentFilterChanges: boolean;
  enableExports: {
    pdf: boolean;
    xlsx: boolean;
    gantt: boolean;
  };
};

const featuresRequiringLicense: Feature[] = [
  Feature.TENANT_TRADE_SEQUENCE_TEMPLATES,
  Feature.TENANT_TRADE_TEMPLATES,
  Feature.TENANT_PROJECT_STRUCTURE_TEMPLATES,
  Feature.TENANT_PROJECT_TEMPLATES,
];

function getDefaultFeatureConditions(): IndividualFeatureConditions & FeatureConditionsLoading {
  const projectStore = useProjectStore();
  const tenantStore = useTenantStore();
  const globalStore = useGlobalStore();
  const orderStore = useOrderStore();
  const membershipStore = useMembershipStore();
  const projectContributorStore = useProjectContributorStore();
  const rtcClient = useRTCClient();

  const { tenantMemberships, ownContributorGroupMemberships } = storeToRefs(membershipStore);

  const projectGroupMemberships = computed(() => {
    return ownContributorGroupMemberships.value.filter(
      (membership) => membership.projectId === projectStore.currentProject?.id,
    );
  });

  const projectMembership = computed(() => {
    return ownContributorGroupMemberships.value.find((membership) => {
      return (
        membership.isProjectMembership && membership.projectId === projectStore.currentProject?.id
      );
    });
  });

  const externalProjectMembers = computed(() => {
    return membershipStore.externalGroupMemberships.filter(
      (membership) => projectStore.currentProject?.id === membership.projectId,
    );
  });

  const projectMemberPermission = computed(
    (): ProjectPermission | null =>
      (projectMembership.value?.permission as ProjectPermission) ?? null,
  );

  const currentTenantMembership = computed(() =>
    tenantMemberships.value.find(
      (membership) => membership.tenant.id === globalStore.currentTenantId,
    ),
  );
  const projectTenantMembership = computed(() =>
    tenantMemberships.value.find(
      (membership) => membership.tenant.id === projectStore.currentProject?.tenant.id,
    ),
  );

  const isGeneralContractor = computed(
    () => tenantStore.ownTenant?.type === TenantType.GeneralContractor,
  );
  const isCurrentTenantMember = computed(() => !!currentTenantMembership.value);

  const isCurrentTenantAdmin = computed(() => currentTenantMembership.value?.isAdmin ?? false);
  const isProjectTenantMember = computed(() => !!projectTenantMembership.value);
  const isProjectTenantAdmin = computed(() => projectTenantMembership.value?.isAdmin ?? false);
  const isPartOfContributorGroupWithWritePermisson = computed(() => {
    return projectGroupMemberships.value.some((membership) => {
      if (membership.permission === ProjectPermission.Editor) {
        const group = projectContributorStore.contributorGroups.get(membership.contributorGroup.id);
        return group?.tenantTradeVariationAssignments.length;
      }
      return false;
    });
  });
  const isExternalProjectMember = computed(() => {
    return externalProjectMembers.value.length > 0;
  });

  const visitsAsProjectOwner = computed(() =>
    // checks if the user is member of the project owner tenant and also visits the project as the owner
    projectStore.currentProject
      ? isProjectTenantMember.value &&
        globalStore.currentTenantId === projectStore.currentProject.tenant.id
      : false,
  );
  const isCurrentProjectReadonly = computed(
    () =>
      projectStore.currentProject?.lifecycleStatus === LifecycleStatus.COMPLETED ||
      projectStore.currentProject?.lifecycleStatus === LifecycleStatus.ARCHIVED,
  );

  const isProjectEditor = computed(
    () => visitsAsProjectOwner.value && projectMemberPermission.value === ProjectPermission.Editor,
  );
  const isProjectReporter = computed(
    () =>
      visitsAsProjectOwner.value &&
      projectMemberPermission.value === ProjectPermission.ProgressReporter,
  );

  const isProjectViewer = computed(
    () => visitsAsProjectOwner.value && projectMemberPermission.value === ProjectPermission.Viewer,
  );

  const isDailyProject = computed(() => !projectStore.currentProject?.hourlyPlanningEnabled);

  const isOnline = useIsOnline();

  const isConnectedToSocket = rtcClient.isConnected;

  const tenantHasFeatureLicense = (feature: Feature) => {
    return computed(() => {
      /**
       * External members don't have defined tenant, it's required that we return early
       * otherwise all feature access values are false.
       */
      if (!featuresRequiringLicense.includes(feature)) return true;

      // return false; // uncomment to test license teasers

      const tenantFeatures = tenantStore.ownTenant?.features;
      if (!tenantFeatures) return false;

      switch (feature) {
        case Feature.TENANT_TRADE_SEQUENCE_TEMPLATES:
          return tenantFeatures.enableTradeSequenceTemplates;
        case Feature.TENANT_TRADE_TEMPLATES:
          return tenantFeatures.enableTradeTemplates;
        case Feature.TENANT_PROJECT_STRUCTURE_TEMPLATES:
          return tenantFeatures.enableProjectStructureTemplates;
        case Feature.TENANT_PROJECT_TEMPLATES:
          //placeholder
          return tenantFeatures.enableProjectStructureTemplates;
        default:
          // if not listed here, the feature does not require a license
          return true;
      }
    });
  };

  const isResponsibleForOrder = (orderId?: string) =>
    computed(() => {
      if (!orderId) return false;
      const order = orderStore.orders.get(orderId);
      if (!order) return false;

      const requiredPermissions = [ProjectPermission.ProgressReporter, ProjectPermission.Editor];

      if (!order.contributorGroup) {
        return Boolean(
          projectMembership.value &&
            requiredPermissions.includes(projectMembership.value.permission as ProjectPermission),
        );
      }

      const groupMembershipExists = projectGroupMemberships.value.some(
        (membership) =>
          membership.contributorGroup.id === order.contributorGroup!.id &&
          requiredPermissions.includes(membership.permission as ProjectPermission),
      );

      return groupMembershipExists;
    });

  const isOrderDoneOrReportedDone = (orderId?: string) =>
    computed(() => {
      if (!orderId) return true;
      const order = orderStore.orders.get(orderId);
      if (!order) return true;
      return !orderIsDoneOrReportedDone(order);
    });

  const isResponsibleForContributorGroup = (contributorGroupId?: string) =>
    computed(() => {
      if (!contributorGroupId) return false;

      const requiredPermissions = [ProjectPermission.ProgressReporter, ProjectPermission.Editor];

      const groupMembershipExists = projectGroupMemberships.value.some(
        (membership) =>
          membership.contributorGroup.id === contributorGroupId &&
          requiredPermissions.includes(membership.permission as ProjectPermission),
      );

      return groupMembershipExists;
    });

  const isAllowedToEditOrder = (order: OrderEntity): boolean => {
    if (isProjectEditor.value || isProjectTenantAdmin.value) return true;

    if (!order.contributorGroup) return false;
    const groupMembership = projectGroupMemberships.value.find(
      (membership) => membership.contributorGroup.id === order.contributorGroup!.id,
    );
    if (!groupMembership) return false;

    if (groupMembership.permission !== ProjectPermission.Editor) return false;

    const groupDetails = projectContributorStore.contributorGroups.get(
      groupMembership.contributorGroup.id,
    );
    if (!groupDetails) return false;

    return groupDetails.tenantTradeVariationAssignments.some(
      (assignment) => assignment.tenantTradeVariation.id === order.tenantTradeVariation.id,
    );
  };

  const isAllowedToEditDependency = (toOrderId?: string) => {
    return computed(() => {
      if (isProjectEditor.value || isProjectTenantAdmin.value) return true;

      if (!toOrderId) return false;

      const toOrder = orderStore.orders.get(toOrderId);
      if (!toOrder) return false;

      return isAllowedToEditOrder(toOrder);
    });
  };

  const isContributorGroupMember = (contributorGroupId?: string) =>
    computed(() => {
      if (!contributorGroupId) return false;

      return ownContributorGroupMemberships.value.some(
        (membership) => membership.contributorGroup.id === contributorGroupId,
      );
    });

  return {
    isGeneralContractor,
    isGroupMember: computed(() => {
      return projectGroupMemberships.value.length > 0;
    }),
    isCurrentTenantMember,
    isCurrentTenantAdmin,
    isProjectTenantMember,
    isProjectTenantAdmin,
    isPartOfContributorGroupWithWritePermisson,
    isExternalProjectMember,
    isCurrentProjectReadonly,
    isProjectEditor,
    isProjectReporter,
    isProjectViewer,
    isConnectedToSocket,
    isDailyProject,
    isOnline,
    tenantHasFeatureLicense,
    isResponsibleForOrder,
    isOrderDoneOrReportedDone,
    isResponsibleForContributorGroup,
    isContributorGroupMember,
    isAllowedToEditDependency,
    isAllowedToEditOrder(orderId) {
      return computed(() => {
        if (!orderId) return false;
        const order = orderStore.orders.get(orderId);

        if (!order) return false;
        return isAllowedToEditOrder(order);
      });
    },
    loading: membershipStore.loading,
  };
}

export type DependencyEditScope = { dependencyId: string } | { toOrder?: { id: string } };

export const useFeatureAccessStore = (
  manualSettings?: IndividualFeatureConditions & FeatureConditionsLoading,
) =>
  defineStore('feature-access-store', () => {
    const {
      isGeneralContractor,
      isCurrentTenantMember,
      isCurrentTenantAdmin,
      isProjectTenantAdmin,
      isExternalProjectMember,
      isCurrentProjectReadonly,
      isProjectEditor,
      isGroupMember,
      isProjectReporter,
      isProjectViewer,
      isPartOfContributorGroupWithWritePermisson,
      isConnectedToSocket,
      isDailyProject,
      isOnline,
      isAllowedToEditDependency,
      tenantHasFeatureLicense,
      isResponsibleForOrder,
      isOrderDoneOrReportedDone,
      isResponsibleForContributorGroup,
      isContributorGroupMember,
      isAllowedToEditOrder,
      loading,
    } = manualSettings || getDefaultFeatureConditions();

    const customAccess: Ref<CustomFeatureAccess | undefined> = ref(undefined);

    const getFeatureAccess = (
      feature: Feature,
      {
        write = [ref(false)],
        read = [ref(true)],
        otherRequired = [],
      }: {
        /** Specifies all permissions that enable write access, if none true, permission denied reason set */
        write?: Ref<boolean>[];
        /** Specifies all permissions that enable read access, if none true, permission denied reason set */
        read?: Ref<boolean>[];
        /** Specifies additional access values that are required for both write and read and create own deny reason */
        otherRequired?: {
          value: Ref<boolean>;
          reason: FeatureDenyReason | null;
          /**
           * Required condition is only relevant for write permission.
           */
          writeOnly?: boolean;
          /**
           * Required condition is only relevant for read permission.
           */
          readOnly?: boolean;
        }[];
      } = {},
    ): Ref<FeatureAccess> => {
      const hasLicense = tenantHasFeatureLicense(feature);

      /**
       * Needs to be computed as we don't access customAccess.value within
       * computed scope and we'd otherwise lose reactivity.
       */
      const getCustomFeatureAccess = () =>
        customAccess.value ? customAccess.value.features[feature] : undefined;
      const hasWritePermissions = computed(
        () =>
          write.some((p) => p.value) &&
          Boolean(otherRequired?.every((r) => r.readOnly || r.value.value)) &&
          (getCustomFeatureAccess()?.write === undefined ||
            getCustomFeatureAccess()?.write?.value === true),
      );

      const hasReadPermissions = computed(
        () =>
          (hasWritePermissions.value ||
            (read.some((p) => p.value) &&
              otherRequired?.every((r) => r.writeOnly || r.value.value))) &&
          (getCustomFeatureAccess()?.read === undefined ||
            getCustomFeatureAccess()?.read?.value === true),
      );
      const otherDenyReason = computed(
        () => otherRequired.find((r) => !r.value.value)?.reason ?? null,
      );

      const getReason = (
        license: boolean,
        permission: boolean,
        other: FeatureDenyReason | null,
      ) => {
        if (other) return other;
        if (!license) return FeatureDenyReason.LICENSE;
        if (!permission) return FeatureDenyReason.PERMISSION;
        return null;
      };

      return computed(() => ({
        write: hasLicense.value && hasWritePermissions.value,
        read: hasLicense.value && hasReadPermissions.value,
        writeDenyReason: getReason(
          hasLicense.value,
          hasWritePermissions.value,
          otherDenyReason.value,
        ),
        readDenyReason: getReason(
          hasLicense.value,
          hasReadPermissions.value,
          otherDenyReason.value,
        ),
      }));
    };

    const requireProjectNotReadonly = {
      value: computed(() => !isCurrentProjectReadonly.value),
      reason: FeatureDenyReason.PROJECT_READONLY,
      writeOnly: true,
    };

    const requireIsOnline = {
      value: isOnline,
      reason: FeatureDenyReason.OFFLINE,
      readOnly: false,
    };

    const requireSocket = {
      value: isConnectedToSocket,
      reason: FeatureDenyReason.SERVER_UNREACHABLE,
      readOnly: false,
    };

    // Helper to still infer the values of the keys while making sure all keys from the enum are present
    function createFeatures<V, T extends { [K in Feature]: V }>(features: T): T {
      return features;
    }

    const features = createFeatures({
      BASE_PLAN: getFeatureAccess(Feature.BASE_PLAN, {
        write: [isProjectEditor, isProjectTenantAdmin],
        otherRequired: [requireIsOnline, requireSocket, requireProjectNotReadonly],
      }),
      DASH: getFeatureAccess(Feature.DASH, {
        write: [isProjectEditor, isProjectTenantAdmin],
        read: [
          isProjectViewer,
          isProjectReporter,
          isProjectEditor,
          isProjectTenantAdmin,
          isGroupMember,
        ],
      }),
      MILESTONE_REPORTING: getFeatureAccess(Feature.MILESTONE_REPORTING, {
        write: [isProjectEditor, isProjectTenantAdmin],
        otherRequired: [requireIsOnline, requireSocket, requireProjectNotReadonly],
      }),
      ORDER_DOCUMENTS: (orderId?: string) => {
        return getFeatureAccess(Feature.ORDER_DOCUMENTS, {
          read: [
            isProjectEditor,
            isProjectReporter,
            isProjectViewer,
            isProjectTenantAdmin,
            isResponsibleForOrder(orderId),
          ],
          write: [isProjectEditor, isProjectTenantAdmin, isAllowedToEditOrder(orderId)],
          otherRequired: [requireIsOnline, requireSocket, requireProjectNotReadonly],
        });
      },
      ORDER_DETAILS: (orderId?: string) =>
        getFeatureAccess(Feature.ORDER_DETAILS, {
          write: [isProjectEditor, isProjectTenantAdmin, isResponsibleForOrder(orderId)],
          read: [
            isProjectEditor,
            isProjectTenantAdmin,
            isProjectViewer,
            isProjectReporter,
            isResponsibleForOrder(orderId),
          ],
        }),
      DEPENDENCY_PLANNING: (toOrderId?: string) =>
        getFeatureAccess(Feature.DEPENDENCY_PLANNING, {
          write: [isProjectEditor, isProjectTenantAdmin, isAllowedToEditDependency(toOrderId)],
          otherRequired: [requireProjectNotReadonly, requireIsOnline, requireSocket],
        }),
      // NOTE: Another example of why we need to refactor the feature access store flags, the ignoreNotFinished is more a hack than a proper solution for event selection
      ORDER_PLANNING: (orderId?: string, ignoreFinished?: boolean) => {
        const requireOrderNotFinished = {
          value: isOrderDoneOrReportedDone(orderId),
          reason: FeatureDenyReason.OBJECT_IMMUTABLE,
          writeOnly: true,
        };

        return getFeatureAccess(Feature.ORDER_PLANNING, {
          write: [
            isProjectEditor,
            isProjectTenantAdmin,
            !orderId ? isPartOfContributorGroupWithWritePermisson : isAllowedToEditOrder(orderId),
          ],
          otherRequired: [
            requireProjectNotReadonly,
            requireIsOnline,
            requireSocket,
            ...(ignoreFinished ? [] : [requireOrderNotFinished]),
          ],
        });
      },
      PHOTO_DOCUMENTATION: getFeatureAccess(Feature.PHOTO_DOCUMENTATION),
      PLANNING_MODE: getFeatureAccess(Feature.PLANNING_MODE, {
        write: [isProjectEditor, isProjectTenantAdmin],
        read: [isProjectEditor, isProjectTenantAdmin],
        otherRequired: [requireProjectNotReadonly],
      }),
      PROGRESS_REPORTING: (report?: { orderId?: string; status?: StatusReport }) => {
        const requireRoleForSetStatus = () => {
          let value = ref(true);
          let reason: null | FeatureDenyReason = null;

          if (!report?.status) return { value, reason };
          switch (report.status) {
            case StatusReport.REJECTED:
              value = computed(
                () =>
                  isProjectEditor.value || isProjectReporter.value || isProjectTenantAdmin.value,
              );
              reason = FeatureDenyReason.PERMISSION;
              break;
            case StatusReport.DONE:
              value = computed(
                () =>
                  isProjectEditor.value || isProjectReporter.value || isProjectTenantAdmin.value,
              );
              reason = FeatureDenyReason.PERMISSION;
              break;
            case StatusReport.REPORTED_DONE:
              value = isExternalProjectMember;
              reason = FeatureDenyReason.PERMISSION;
              break;
            default:
              break;
          }
          return { value, reason, writeOnly: true };
        };
        return getFeatureAccess(Feature.PROGRESS_REPORTING, {
          write: [
            isProjectEditor,
            isProjectReporter,
            isProjectTenantAdmin,
            isResponsibleForOrder(report?.orderId),
          ],
          read: [
            isProjectViewer,
            isProjectEditor,
            isProjectReporter,
            isProjectTenantAdmin,
            isResponsibleForOrder(report?.orderId),
          ],
          otherRequired: [
            requireRoleForSetStatus(),
            requireIsOnline,
            requireSocket,
            requireProjectNotReadonly,
          ],
        });
      },
      PROJECT_SETTINGS: getFeatureAccess(Feature.PROJECT_SETTINGS, {
        write: [isProjectEditor, isProjectTenantAdmin],
        otherRequired: [requireProjectNotReadonly],
      }),
      SCHEDULE_EXPORT: getFeatureAccess(Feature.SCHEDULE_EXPORT),
      SCHEDULE_PLANNING: () =>
        getFeatureAccess(Feature.SCHEDULE_PLANNING, {
          write: [isProjectEditor, isProjectTenantAdmin],
          otherRequired: [requireProjectNotReadonly, requireIsOnline, requireSocket],
        }),
      TEAM_MEMBERS: getFeatureAccess(Feature.TEAM_MEMBERS, {
        write: [isProjectEditor, isProjectTenantAdmin],
      }),
      TENANT: getFeatureAccess(Feature.TENANT, {
        // Currently no write use case so only read is defined
        read: [isCurrentTenantMember],
      }),
      TENANT_PROJECTS: getFeatureAccess(Feature.TENANT_PROJECTS, {
        write: [isCurrentTenantMember],
        read: [isCurrentTenantMember],
      }),
      TENANT_SETTINGS: getFeatureAccess(Feature.TENANT_SETTINGS, {
        write: [isCurrentTenantAdmin],
        read: [isCurrentTenantMember],
      }),
      TENANT_TRADE_SEQUENCE_TEMPLATES: getFeatureAccess(Feature.TENANT_TRADE_SEQUENCE_TEMPLATES, {
        write: [isCurrentTenantAdmin],
        read: [isCurrentTenantMember],
        otherRequired: [{ value: isGeneralContractor, reason: FeatureDenyReason.PERMISSION }],
      }),
      TENANT_TRADE_TEMPLATES: getFeatureAccess(Feature.TENANT_TRADE_TEMPLATES, {
        write: [isCurrentTenantAdmin],
        read: [isCurrentTenantMember],
        otherRequired: [{ value: isGeneralContractor, reason: FeatureDenyReason.PERMISSION }],
      }),
      TENANT_PROJECT_STRUCTURE_TEMPLATES: getFeatureAccess(
        Feature.TENANT_PROJECT_STRUCTURE_TEMPLATES,
        {
          write: [isCurrentTenantAdmin],
          read: [isCurrentTenantMember],
          otherRequired: [{ value: isGeneralContractor, reason: FeatureDenyReason.PERMISSION }],
        },
      ),
      TENANT_PROJECT_TEMPLATES: getFeatureAccess(Feature.TENANT_PROJECT_TEMPLATES, {
        write: [isCurrentTenantAdmin],
        read: [isCurrentTenantMember],
        otherRequired: [{ value: isGeneralContractor, reason: FeatureDenyReason.PERMISSION }],
      }),
      TICKETS: getFeatureAccess(Feature.TICKETS, {
        write: [isProjectEditor, isProjectReporter, isProjectTenantAdmin],
        otherRequired: [requireIsOnline, requireSocket, requireProjectNotReadonly],
      }),
      TICKET_REPORTING: (ticket?: { contributorGroupId?: string; status?: TicketStatus }) => {
        const requireRoleForSetStatus = () => {
          let value = ref(true);
          let reason: null | FeatureDenyReason = null;

          if (!ticket?.status) return { value, reason };
          switch (ticket.status) {
            case TicketStatus.CLOSED:
              value = computed(
                () =>
                  isProjectEditor.value || isProjectTenantAdmin.value || isProjectReporter.value,
              );
              reason = FeatureDenyReason.PERMISSION;
              break;
            case TicketStatus.REPORTED_DONE:
              value = isExternalProjectMember;
              reason = FeatureDenyReason.PERMISSION;
              break;
            default:
              break;
          }
          return { value, reason, writeOnly: true };
        };

        return getFeatureAccess(Feature.TICKET_REPORTING, {
          write: [
            isProjectEditor,
            isProjectReporter,
            isProjectTenantAdmin,
            isResponsibleForContributorGroup(ticket?.contributorGroupId),
          ],
          otherRequired: [
            requireRoleForSetStatus(),

            requireIsOnline,
            requireSocket,
            requireProjectNotReadonly,
          ],
        });
      },
      TRADE_SEQUENCES: getFeatureAccess(Feature.TRADE_SEQUENCES, {
        write: [isProjectEditor, isProjectTenantAdmin],
        read: [isProjectEditor, isProjectReporter, isProjectViewer, isProjectTenantAdmin],
        otherRequired: [
          { value: isDailyProject, reason: FeatureDenyReason.HOURLY_MODE },
          requireIsOnline,
          requireSocket,
          requireProjectNotReadonly,
        ],
      }),
      VERSIONING: getFeatureAccess(Feature.VERSIONING, {
        write: [isProjectEditor, isProjectTenantAdmin],
        read: [isProjectEditor, isProjectTenantAdmin],
        otherRequired: [requireIsOnline, requireSocket, requireProjectNotReadonly],
      }),
      PROJECT_CONTRIBUTORS: getFeatureAccess(Feature.PROJECT_CONTRIBUTORS, {
        write: [isProjectEditor, isProjectTenantAdmin],
        otherRequired: [],
      }),
      /**
       * Write authorizes the invitation of other users.
       * @param args
       * @returns
       */
      PROJECT_CONTRIBUTOR_MEMBERS: (args?: { contributorGroupId: string }) => {
        return getFeatureAccess(Feature.PROJECT_CONTRIBUTOR_MEMBERS, {
          write: [
            isProjectEditor,
            isProjectTenantAdmin,
            isContributorGroupMember(args?.contributorGroupId),
          ],
          otherRequired: [],
        });
      },
      DELETE_PROJECT_CONTRIBUTOR_MEMBERS: getFeatureAccess(
        Feature.DELETE_PROJECT_CONTRIBUTOR_MEMBERS,
        {
          write: [isProjectEditor, isProjectTenantAdmin],
          otherRequired: [],
        },
      ),
    });
    // eslint-disable-next-line complexity
    const uiState = computed<ConfigurableUIState>(() => {
      const customUIState = customAccess.value?.uiState;

      return {
        showLiveAvatars: customUIState?.showLiveAvatars ?? true,
        showUndoRedo: customUIState?.showUndoRedo ?? true,
        enableElementSidebars: customUIState?.enableElementSidebars ?? true,
        showCalendars: customUIState?.showCalendars ?? true,
        showSectionActions: customUIState?.showSectionActions ?? true,
        showCollisionNotifications: customUIState?.showCollisionNotifications ?? true,
        allowPermanentFilterChanges: customUIState?.allowPermanentFilterChanges ?? true,
        enableExports: {
          pdf: customUIState?.enableExports?.pdf ?? true,
          xlsx: customUIState?.enableExports?.xlsx ?? true,
          gantt: customUIState?.enableExports?.gantt ?? true,
        },
      };
    });

    return {
      features,
      loading,
      uiState,
      hasEditAccessToAllTrades: computed(() => {
        return isProjectEditor.value || isProjectTenantAdmin.value;
      }),
      hasWriteAccessToCalendars: computed(() => {
        return features.SCHEDULE_PLANNING().value.write;
      }),
      hasWriteAccessToSections: computed(() => {
        return features.SCHEDULE_PLANNING().value.write;
      }),
      hasWriteAccessToPausesAndMilestones: computed(() => {
        return features.SCHEDULE_PLANNING().value.write;
      }),
      hasWriteAccessToScheduleData: computed(() => {
        return features.SCHEDULE_PLANNING().value.write || features.ORDER_PLANNING().value.write;
      }),
      setCustomAccess(access: CustomFeatureAccess) {
        customAccess.value = access;
      },
      removeCustomAccess(access: Pick<CustomFeatureAccess, 'id'>) {
        if (customAccess.value?.id === access.id) {
          customAccess.value = undefined;
        }
      },
    };
  })();

export type FeatureAccessStore = ReturnType<typeof useFeatureAccessStore>;
